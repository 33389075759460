<template>
	<div>
		<h2 class="text-mobile-sub-title sub-title ml-0 mb-2">{{ FormMSG(256, 'Project information') }}</h2>

		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(257, 'Project name')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.projectName.$model"
				:state="getStateValidationField('dataToSend.projectName')"
				:placeholder="FormMSG(2101, 'Enter project name')"
			/>
			<div v-if="$v.dataToSend.projectName.$error && getStateValidationField('dataToSend.projectName') !== null" class="invalid-feedback">
				{{ FormMSG(2102, 'Please enter project name') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(258, 'Project type')" />
			</template>
			<b-form-select
				v-model="$v.dataToSend.productionType.$model"
				:state="getStateValidationField('dataToSend.productionType')"
				:options="mapTgsproductionType"
			/>
			<div v-if="$v.dataToSend.productionType.$error && getStateValidationField('dataToSend.productionType') !== null" class="invalid-feedback">
				{{ FormMSG(2559, 'Please select an project type') }}
			</div>
		</b-form-group>

		<h2 class="text-mobile-sub-title sub-title ml-0 mb-2">{{ FormMSG(259, 'Contract information') }}</h2>

		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(260, 'Department')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.contractDepartment.$model"
				:state="getStateValidationField('dataToSend.contractDepartment')"
				:placeholder="FormMSG(2103, 'Enter department')"
			/>
			<div v-if="$v.dataToSend.contractDepartment.$error && getStateValidationField('dataToSend.contractDepartment') !== null" class="invalid-feedback">
				{{ FormMSG(2102, 'Please enter department') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(261, 'Function')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.contractFunction.$model"
				:state="getStateValidationField('dataToSend.contractFunction')"
				:placeholder="FormMSG(2104, 'Enter function')"
			/>
			<div v-if="$v.dataToSend.contractFunction.$error && getStateValidationField('dataToSend.contractFunction') !== null" class="invalid-feedback">
				{{ FormMSG(2105, 'Please enter function') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(262, 'Start date')" />
			</template>
			<v-date-picker :value="$v.dataToSend.contractStartDate.$model" :masks="{ input: 'DD/MM/YYYY' }" @input="handleInputStartDate">
				<template v-slot="{ inputValue, togglePopover }">
					<b-input-group>
						<b-form-input :value="inputValue" readonly class="bg-white" @click="togglePopover" />
						<b-input-group-append>
							<b-input-group-text class="cursor-pointer" @click="togglePopover">
								<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</template>
			</v-date-picker>
			<div v-if="$v.dataToSend.contractStartDate.$error && getStateValidationField('dataToSend.contractStartDate') !== null" class="invalid-feedback">
				{{ FormMSG(2110, 'Please enter start date') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(269, 'End date')" />
			</template>
			<v-date-picker
				:value="$v.dataToSend.contractEndDate.$model"
				:masks="{ input: 'DD/MM/YYYY' }"
				:min-date="dataToSend.contractStartDate"
				@input="handleInputEndDate"
			>
				<template v-slot="{ inputValue, togglePopover }">
					<b-input-group>
						<b-form-input :value="inputValue" readonly class="bg-white" @click="togglePopover" />
						<b-input-group-append>
							<b-input-group-text class="cursor-pointer" @click="togglePopover">
								<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</template>
			</v-date-picker>
			<div v-if="$v.dataToSend.contractEndDate.$error && getStateValidationField('dataToSend.contractStartDate') !== null" class="invalid-feedback">
				{{ FormMSG(2111, 'Please enter end date') }}
			</div>
		</b-form-group>
		<b-form-group :label="FormMSG(681, 'Specify working days')">
			<b-form-checkbox
				v-model="dataToSend.specifyWorkingDays"
				size="lg"
				switch
				:value="true"
				:unchecked-value="false"
				@change="handleChangeSpecifyWorkingDays"
			/>
		</b-form-group>

		<b-collapse :visible="dataToSend.specifyWorkingDays">
			<b-row v-if="dataToSend.specifyWorkingDays" class="mb-2">
				<b-col cols="12">
					<Calendar
						:min-date="minDateValue"
						:max-date="maxDateValue"
						:attributes="attributes"
						:locale="lang"
						is-expanded
						@dayclick="handleDayClick"
					/>
					<div v-if="invalidDateSelected === true" class="invalid-feedback">
						{{ FormMSG(278, 'Please select one or more dates') }}
					</div>
				</b-col>
			</b-row>
		</b-collapse>

		<b-form-group v-if="!dataToSend.specifyWorkingDays">
			<template #label>
				<custom-label-mandatory :label="FormMSG(270, 'Days foreseen')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.contractDaysForeseen.$model"
				:state="getStateValidationField('dataToSend.contractDaysForeseen')"
				:placeholder="FormMSG(2115, 'Enter days foreseen')"
			/>
			<div
				v-if="!$v.dataToSend.contractDaysForeseen.required && getStateValidationField('dataToSend.contractDaysForeseen') !== null"
				class="invalid-feedback"
			>
				{{ FormMSG(2116, 'Please enter days foreseen') }}
			</div>
			<div
				v-if="!$v.dataToSend.contractDaysForeseen.minValue && getStateValidationField('dataToSend.contractDaysForeseen') !== null"
				class="invalid-feedback"
			>
				{{ FormMSG(2117, 'The contract days foreseen must greater than 0') }}
			</div>
		</b-form-group>

		<b-row>
			<b-col sm="12" md="6" lg="6" xl="6">
				<b-form-group>
					<template #label>
						<custom-label-mandatory :label="FormMSG(271, 'Daily rate')" not-required />
					</template>
					<b-input-group>
						<b-form-input v-model="dataToSend.contractDailyRate" :placeholder="FormMSG(2220, 'Enter daily rate')" />
						<b-input-group-append>
							<b-input-group-text class="cursor-pointer">
								<component :is="getLucideIcon('Euro')" :size="16" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="6" lg="6" xl="6">
				<b-form-group :label="FormMSG(364, 'Salary type')">
					<b-form-select v-model="dataToSend.salaryType" :options="salaryTypeOptions" />
				</b-form-group>
			</b-col>
		</b-row>

		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(272, 'Daily hours')" not-required />
			</template>
			<input-date-time-picker
				:label="FormMSG(272, 'Daily hours')"
				:uiid="'minDay'"
				:type="'text'"
				:placeholder="'hh:mm'"
				:item="dataToSend.contractDailyHours"
				:nbHour="rangeHour[WORKTYPE.daily]"
				:nbMinute="rangeMinute[WORKTYPE.daily]"
				:isEditable="() => true"
				:fieldName="'totMinPerDay'"
				:workType="WORKTYPE.daily"
				@change="handleChangeHourMin"
			/>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(297, 'Additional note (concerning deal memo)')" not-required />
			</template>
			<b-form-textarea v-model="dataToSend.contractNote" />
		</b-form-group>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import CustomLabelMandatory from './CustomLabelMandatory.vue';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Calendar from 'v-calendar/lib/components/calendar.umd';
import { validationMixin } from 'vuelidate';
import { minValue, required } from 'vuelidate/lib/validators';
import InputDateTimePicker from '@/components/InputDateTimePicker/InputDateTimePicker';
import { useWorktype, useRangeHour, useRangeMinute } from '@/utils/utils';
import moment from 'moment';
import { SALARY_TYPE_MENU } from '../../../shared/constants';

export default {
	name: 'StepFourForm',

	mixins: [languageMessages, globalMixin, validationMixin],

	components: {
		Calendar,
		'v-date-picker': DatePicker,
		CustomLabelMandatory,
		InputDateTimePicker
	},

	props: {
		initData: { type: Object, default: () => {} }
	},

	data() {
		return {
			WORKTYPE: useWorktype,
			rangeHour: useRangeHour,
			rangeMinute: useRangeMinute,
			invalidDateSelected: false,
			dataToSend: {
				projectName: null,
				productionType: null,
				contractDepartment: null,
				contractFunction: null,
				contractStartDate: new Date(),
				contractEndDate: new Date(),
				contractDaysForeseen: 1,
				contractDailyRate: 0,
				contractDailyHours: '00:00',
				contractNote: '',
				workingDates: [],
				specifyWorkingDays: false,
				salaryType: 0
			}
		};
	},

	watch: {
		initData: {
			handler(newVal) {
				this.dataToSend = newVal;
			},
			immediate: true
		},
		dataToSend: {
			handler(newVal) {
				this.$emit('step-four-form:change', { data: newVal });
			},
			deep: true
		}
	},

	computed: {
		salaryTypeOptions() {
			return [{ value: 0, text: this.FormMSG(2556, 'Please select ...') }, ...this.FormMenu(SALARY_TYPE_MENU)];
		},
		dates() {
			return this.dataToSend.workingDates.map((day) => day.date);
		},
		attributes() {
			return this.dates.map((date) => ({
				highlight: true,
				dates: date,
				content: 'red'
			}));
		},
		minDateValue() {
			return moment(new Date(this.dataToSend.contractStartDate)).format();
		},
		maxDateValue() {
			return moment(new Date(this.dataToSend.contractEndDate)).format();
		},
		mapTgsproductionType() {
			let type = [
				{
					value: null,
					text: this.FormMSG(2556, 'Please select ...')
				},
				{
					label: this.FormMSG(1000, 'Film'),
					options: this.FormMenu(1350)
				},
				{
					label: this.FormMSG(1001, 'TV/ Audiovisual'),
					options: this.FormMenu(1351)
				},
				{
					label: this.FormMSG(1002, 'Shows'),
					options: this.FormMenu(1352)
				},
				{
					label: this.FormMSG(1003, 'Publicity'),
					options: this.FormMenu(1353)
				},
				{
					label: this.FormMSG(1004, 'Other'),
					options: this.FormMenu(1354)
				}
			];
			return type;
		}
	},

	methods: {
		handleChangeSpecifyWorkingDays(payload) {
			if (!payload) {
				this.dataToSend.workingDates = [];
				this.activeCalculDate();
			} else {
				this.dataToSend.contractDaysForeseen = 0;
			}
		},
		handleDayClick(payload) {
			const idx = this.dataToSend.workingDates.findIndex((option) => option.date === moment(new Date(payload.id)).format('YYYY-MM-DD') + 'T00:00:00Z');

			if (idx >= 0) {
				this.dataToSend.workingDates.splice(idx, 1);
			} else {
				this.invalidDateSelected = false;
				this.dataToSend.workingDates.push({
					date: moment(new Date(payload.id)).format('YYYY-MM-DD') + 'T00:00:00Z'
				});
			}
			this.dataToSend.contractDaysForeseen = this.dataToSend.workingDates.length || 0;
		},
		isInvalid() {
			this.$v.$touch();

			if (this.dataToSend.specifyWorkingDays && this.dataToSend.workingDates.length === 0) {
				this.invalidDateSelected = true;
			} else {
				this.invalidDateSelected = false;
			}

			return this.$v.$invalid || this.invalidDateSelected;
		},
		handleChangeHourMin(payload) {
			this.dataToSend.contractDailyHours = payload.hourValid;
			// this.convertHoursToMinutes(payload);
		},
		setActionInputDateTimePicker() {
			this.WORKTYPE = useWorktype;
			this.rangeHour = useRangeHour;
			this.rangeMinute = useRangeMinute;
		},
		handleInputStartDate(payload) {
			this.dataToSend.contractStartDate = payload;

			if (new Date(this.dataToSend.contractStartDate).getTime() > new Date(this.dataToSend.contractEndDate).getDate()) {
				this.dataToSend.contractEndDate = payload;
			}
			this.activeCalculDate();
		},
		calculNbOfDate(date1, date2) {
			if (date1 && date2) {
				const dateDepart = new Date(date1);
				const dateArrivee = new Date(date2);
				let differenceEnJours = 0;
				for (let date = dateDepart; date <= dateArrivee; date.setDate(date.getDate() + 1)) {
					if (date.getDay() !== 6 && date.getDay() !== 0) {
						differenceEnJours++;
					}
				}
				return differenceEnJours;
			}
			return 0;
		},
		activeCalculDate() {
			const dateStart = moment(this.dataToSend.contractStartDate).utc().format('YYYY-MM-DD');
			const dateEnd = moment(this.dataToSend.contractEndDate).utc().format('YYYY-MM-DD');
			const totalOfDays = this.calculNbOfDate(dateStart, dateEnd);
			this.dataToSend.contractDaysForeseen = totalOfDays;
		},
		handleInputEndDate(payload) {
			if (!this.dataToSend.specifyWorkingDays) {
				this.dataToSend.contractEndDate = payload;
				this.activeCalculDate();
			}
		},
		getStateValidationField(field) {
			const customCond = `this.$v.${field}.$error === false`;
			if (eval(customCond)) {
				return null;
			}

			return false;
		}
	},

	validations() {
		return {
			dataToSend: {
				projectName: { required },
				productionType: { required },
				contractDepartment: { required },
				contractFunction: { required },
				contractStartDate: { required },
				contractEndDate: { required },
				contractDaysForeseen: { required, minValue: minValue(1) }
			}
		};
	}
};
</script>
