var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form container-layout mobile-container" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              attrs: {
                sm: "12",
                "offset-sm": "0",
                md: "10",
                "offset-md": "1",
                lg: "6",
                "offset-lg": "3",
                xl: "6",
                "offset-xl": "3",
              },
            },
            [
              !_vm.submitted
                ? _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-body",
                        [
                          _vm.$screen.width <= 576
                            ? _c(
                                "b-row",
                                { staticClass: "mb-3" },
                                [
                                  _c("b-col", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-center",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "navbar-brand",
                                            staticStyle: { cursor: "pointer" },
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "navbar-brand-full",
                                              attrs: {
                                                src: "img/brand/thegreenshot-brand.png",
                                                width: "100",
                                                height: "30",
                                                alt: "TheGreenShot",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            { staticClass: "mb-4" },
                            [
                              _c("b-col", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "fs-20 fw-700 text-color-rhapsody-in-blue",
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.titleLabel) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: { visible: _vm.currentStep === 1 },
                                    },
                                    [
                                      _c("step-one-form", {
                                        on: {
                                          "step-one-form:ok":
                                            _vm.handleStepOneFormOk,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: { visible: _vm.currentStep === 2 },
                                    },
                                    [
                                      _c("step-two-form", {
                                        ref: "step-two-form",
                                        attrs: {
                                          "user-data": _vm.originalDataStepTwo,
                                        },
                                        on: {
                                          "step-two-form:change":
                                            _vm.handleStepTwoFormChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: { visible: _vm.currentStep === 3 },
                                    },
                                    [
                                      _c("step-three-form", {
                                        ref: "step-three-form",
                                        attrs: {
                                          "init-data":
                                            _vm.originalDataStepThree,
                                        },
                                        on: {
                                          "step-three-form:change":
                                            _vm.handleStepThreeFormChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: { visible: _vm.currentStep === 4 },
                                    },
                                    [
                                      _c("step-four-form", {
                                        ref: "step-four-form",
                                        attrs: {
                                          "init-data": _vm.originalDataStepFour,
                                        },
                                        on: {
                                          "step-four-form:change":
                                            _vm.handleStepFourFormChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-card-footer",
                        { staticClass: "bg-white" },
                        [
                          _vm.$screen.width <= 576
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-center",
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "dot-step mr-3",
                                        class: {
                                          finished: _vm.currentStep === 1,
                                          current: _vm.currentStep > 1,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickDot(1)
                                          },
                                        },
                                      }),
                                      _c("div", {
                                        staticClass: "dot-step mr-3",
                                        class: {
                                          finished: _vm.currentStep === 2,
                                          current: _vm.currentStep > 2,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickDot(2)
                                          },
                                        },
                                      }),
                                      _c("div", {
                                        staticClass: "dot-step mr-3",
                                        class: {
                                          finished: _vm.currentStep === 3,
                                          current: _vm.currentStep > 3,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickDot(3)
                                          },
                                        },
                                      }),
                                      _c("div", {
                                        staticClass: "dot-step",
                                        class: {
                                          finished: _vm.currentStep === 4,
                                          current: _vm.currentStep > 4,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickDot(4)
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$screen.width > 576
                            ? _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "text-color-rhapsody-in-blue fw-600 d-flex align-items-center",
                                      attrs: {
                                        sm: "6",
                                        md: "4",
                                        lg: "4",
                                        xl: "4",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.stepLabel))]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "hide-sm d-flex align-items-center justify-content-center",
                                      attrs: { md: "4", lg: "4", xl: "4" },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "dot-step mr-3",
                                        class: {
                                          finished: _vm.currentStep === 1,
                                          current: _vm.currentStep > 1,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickDot(1)
                                          },
                                        },
                                      }),
                                      _c("div", {
                                        staticClass: "dot-step mr-3",
                                        class: {
                                          finished: _vm.currentStep === 2,
                                          current: _vm.currentStep > 2,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickDot(2)
                                          },
                                        },
                                      }),
                                      _c("div", {
                                        staticClass: "dot-step mr-3",
                                        class: {
                                          finished: _vm.currentStep === 3,
                                          current: _vm.currentStep > 3,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickDot(3)
                                          },
                                        },
                                      }),
                                      _c("div", {
                                        staticClass: "dot-step",
                                        class: {
                                          finished: _vm.currentStep === 4,
                                          current: _vm.currentStep > 4,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleClickDot(4)
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "d-flex justify-content-end",
                                      attrs: {
                                        sm: "6",
                                        md: "4",
                                        lg: "4",
                                        xl: "4",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _vm.currentStep > 1
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    variant: "primary",
                                                    disabled: _vm.loadingSubmit,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.handleClickPrevious,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "SkipBack"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: { size: 16 },
                                                    }
                                                  ),
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(458, "Prev")
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t"
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.showNextBtn
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "primary",
                                                    disabled:
                                                      _vm.disableBtnNext,
                                                  },
                                                  on: {
                                                    click: _vm.handleClickNext,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(459, "Next")
                                                      ) +
                                                      " "
                                                  ),
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "SkipForward"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: { size: 16 },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.currentStep === 4
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "primary",
                                                    disabled:
                                                      !_vm.followStepProgressed
                                                        .stepFourOk ||
                                                      _vm.loadingSubmit,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.handleClickSubmit,
                                                  },
                                                },
                                                [
                                                  _vm.loadingSubmit
                                                    ? _c("b-spinner", {
                                                        attrs: { small: "" },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          460,
                                                          "Submit"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                  !_vm.loadingSubmit
                                                    ? _c(
                                                        _vm.getLucideIcon(
                                                          "Send"
                                                        ),
                                                        {
                                                          tag: "component",
                                                          attrs: { size: 16 },
                                                        }
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.$screen.width <= 576
                            ? _c(
                                "b-row",
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "text-color-rhapsody-in-blue fw-600 d-flex align-items-center",
                                      attrs: { cols: "4" },
                                    },
                                    [_vm._v(_vm._s(_vm.stepLabel))]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "d-flex justify-content-end",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _vm.currentStep > 1
                                            ? _c(
                                                "b-button",
                                                {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    variant: "primary",
                                                    size: "sm",
                                                    disabled: _vm.loadingSubmit,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.handleClickPrevious,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "SkipBack"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: { size: 16 },
                                                    }
                                                  ),
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(458, "Prev")
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t"
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.showNextBtn
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "primary",
                                                    size: "sm",
                                                    disabled:
                                                      _vm.disableBtnNext,
                                                  },
                                                  on: {
                                                    click: _vm.handleClickNext,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(459, "Next")
                                                      ) +
                                                      " "
                                                  ),
                                                  _c(
                                                    _vm.getLucideIcon(
                                                      "SkipForward"
                                                    ),
                                                    {
                                                      tag: "component",
                                                      attrs: { size: 16 },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.currentStep === 4
                                            ? _c(
                                                "b-button",
                                                {
                                                  attrs: {
                                                    variant: "primary",
                                                    disabled:
                                                      !_vm.followStepProgressed
                                                        .stepFourOk ||
                                                      _vm.loadingSubmit,
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.handleClickSubmit,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          460,
                                                          "Submit"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c(
                                                    _vm.getLucideIcon("Send"),
                                                    {
                                                      tag: "component",
                                                      attrs: { size: 16 },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", [
                                _c("div", { staticClass: "text-center" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-color-rhapsody-in-blue fs-20 fw-600",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              1687,
                                              "Congratulation !"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-3" },
                            [
                              _c("b-col", [
                                _c("div", { staticClass: "text-center" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-color-rhapsody-in-blue fs-20 fw-600",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              1688,
                                              "Your submission has been sent under the Job Request number"
                                            )
                                          ) +
                                          " " +
                                          _vm._s(_vm.jobRequestNumber) +
                                          " .\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-3" },
                            [
                              _c("b-col", [
                                _c("div", { staticClass: "text-center" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-color-rhapsody-in-blue fs-20 fw-600",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              1708,
                                              "You can add a document to your Job request (like your deal memo descritpion) here:"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-3 mb-2" },
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    sm: _vm.filesToUpload.length > 0 ? 11 : 12,
                                    md: _vm.filesToUpload.length > 0 ? 11 : 12,
                                    lg: _vm.filesToUpload.length > 0 ? 11 : 12,
                                    xl: _vm.filesToUpload.length > 0 ? 11 : 12,
                                  },
                                },
                                [
                                  _c("b-form-file", {
                                    staticStyle: {
                                      "margin-bottom": "0 !important",
                                    },
                                    attrs: {
                                      multiple: "",
                                      "file-name-formatter":
                                        _vm.formatNameFiles,
                                      placeholder: _vm.FormMSG(
                                        888,
                                        "Drag files to download"
                                      ),
                                    },
                                    on: { input: _vm.handleInputFiles },
                                    model: {
                                      value: _vm.filesToUpload,
                                      callback: function ($$v) {
                                        _vm.filesToUpload = $$v
                                      },
                                      expression: "filesToUpload",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.filesToUpload.length > 0
                                ? _c(
                                    "b-col",
                                    {
                                      staticClass: "pl-0 pr-0",
                                      attrs: {
                                        sm: "1",
                                        md: "1",
                                        lg: "1",
                                        xl: "1",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            block: "",
                                            variant: "outline-danger",
                                            size: "sm",
                                          },
                                          on: {
                                            click:
                                              _vm.handleClickRemoveFilesSelected,
                                          },
                                        },
                                        [
                                          _c(_vm.getLucideIcon("X"), {
                                            tag: "component",
                                            attrs: { size: 16 },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-2" },
                            [
                              _c(
                                "b-col",
                                { attrs: { sm: "12" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        block: "",
                                        variant: "primary",
                                        disabled:
                                          _vm.filesToUpload.length === 0 ||
                                          _vm.loadingSendDoc,
                                      },
                                      on: { click: _vm.handleClickSend },
                                    },
                                    [
                                      _vm.loadingSendDoc
                                        ? _c("b-spinner", {
                                            attrs: { small: "" },
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(1711, "Send")) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-3" },
                            [
                              _c("b-col", [
                                _c("div", { staticClass: "text-center" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-color-rhapsody-in-blue fs-20 fw-600",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              1709,
                                              "Once done, you can close this window."
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }