<template>
	<div class="form container-layout mobile-container">
		<b-row>
			<b-col sm="12" offset-sm="0" md="10" offset-md="1" lg="6" offset-lg="3" xl="6" offset-xl="3">
				<b-card v-if="!submitted" no-body>
					<b-card-body>
						<b-row v-if="$screen.width <= 576" class="mb-3">
							<b-col>
								<div class="d-flex justify-content-center">
									<a class="navbar-brand" style="cursor: pointer">
										<img class="navbar-brand-full" src="img/brand/thegreenshot-brand.png" width="100" height="30" alt="TheGreenShot" />
									</a>
								</div>
							</b-col>
						</b-row>
						<b-row class="mb-4">
							<b-col>
								<div class="fs-20 fw-700 text-color-rhapsody-in-blue">
									{{ titleLabel }}
								</div>
							</b-col>
						</b-row>
						<b-row>
							<b-col>
								<b-collapse :visible="currentStep === 1">
									<step-one-form @step-one-form:ok="handleStepOneFormOk" />
								</b-collapse>
								<b-collapse :visible="currentStep === 2">
									<step-two-form ref="step-two-form" :user-data="originalDataStepTwo" @step-two-form:change="handleStepTwoFormChange" />
								</b-collapse>
								<b-collapse :visible="currentStep === 3">
									<step-three-form
										ref="step-three-form"
										:init-data="originalDataStepThree"
										@step-three-form:change="handleStepThreeFormChange"
									/>
								</b-collapse>
								<b-collapse :visible="currentStep === 4">
									<step-four-form ref="step-four-form" :init-data="originalDataStepFour" @step-four-form:change="handleStepFourFormChange" />
								</b-collapse>
							</b-col>
						</b-row>
					</b-card-body>
					<b-card-footer class="bg-white">
						<b-row v-if="$screen.width <= 576">
							<b-col class="d-flex align-items-center justify-content-center">
								<div class="dot-step mr-3" :class="{ finished: currentStep === 1, current: currentStep > 1 }" @click="handleClickDot(1)" />
								<div class="dot-step mr-3" :class="{ finished: currentStep === 2, current: currentStep > 2 }" @click="handleClickDot(2)" />
								<div class="dot-step mr-3" :class="{ finished: currentStep === 3, current: currentStep > 3 }" @click="handleClickDot(3)" />
								<div class="dot-step" :class="{ finished: currentStep === 4, current: currentStep > 4 }" @click="handleClickDot(4)" />
							</b-col>
						</b-row>
						<b-row v-if="$screen.width > 576">
							<b-col sm="6" md="4" lg="4" xl="4" class="text-color-rhapsody-in-blue fw-600 d-flex align-items-center">{{ stepLabel }}</b-col>
							<b-col class="hide-sm d-flex align-items-center justify-content-center" md="4" lg="4" xl="4">
								<div class="dot-step mr-3" :class="{ finished: currentStep === 1, current: currentStep > 1 }" @click="handleClickDot(1)" />
								<div class="dot-step mr-3" :class="{ finished: currentStep === 2, current: currentStep > 2 }" @click="handleClickDot(2)" />
								<div class="dot-step mr-3" :class="{ finished: currentStep === 3, current: currentStep > 3 }" @click="handleClickDot(3)" />
								<div class="dot-step" :class="{ finished: currentStep === 4, current: currentStep > 4 }" @click="handleClickDot(4)" />
							</b-col>
							<b-col sm="6" md="4" lg="4" xl="4" class="d-flex justify-content-end">
								<div>
									<b-button v-if="currentStep > 1" variant="primary" class="mr-2" :disabled="loadingSubmit" @click="handleClickPrevious">
										<component :is="getLucideIcon('SkipBack')" :size="16" />
										{{ FormMSG(458, 'Prev') }}
									</b-button>
									<b-button v-if="showNextBtn" variant="primary" :disabled="disableBtnNext" @click="handleClickNext">
										{{ FormMSG(459, 'Next') }} <component :is="getLucideIcon('SkipForward')" :size="16" />
									</b-button>
									<b-button
										v-if="currentStep === 4"
										variant="primary"
										:disabled="!followStepProgressed.stepFourOk || loadingSubmit"
										@click="handleClickSubmit"
									>
										<b-spinner v-if="loadingSubmit" small /> {{ FormMSG(460, 'Submit') }}
										<component v-if="!loadingSubmit" :is="getLucideIcon('Send')" :size="16" />
									</b-button>
								</div>
							</b-col>
						</b-row>
						<b-row v-if="$screen.width <= 576" class="mt-3">
							<b-col cols="4" class="text-color-rhapsody-in-blue fw-600 d-flex align-items-center">{{ stepLabel }}</b-col>
							<b-col cols="8" class="d-flex justify-content-end">
								<div>
									<b-button
										v-if="currentStep > 1"
										variant="primary"
										class="mr-2"
										size="sm"
										:disabled="loadingSubmit"
										@click="handleClickPrevious"
									>
										<component :is="getLucideIcon('SkipBack')" :size="16" />
										{{ FormMSG(458, 'Prev') }}
									</b-button>
									<b-button v-if="showNextBtn" variant="primary" size="sm" :disabled="disableBtnNext" @click="handleClickNext">
										{{ FormMSG(459, 'Next') }} <component :is="getLucideIcon('SkipForward')" :size="16" />
									</b-button>
									<b-button
										v-if="currentStep === 4"
										variant="primary"
										:disabled="!followStepProgressed.stepFourOk || loadingSubmit"
										@click="handleClickSubmit"
									>
										{{ FormMSG(460, 'Submit') }} <component :is="getLucideIcon('Send')" :size="16" />
									</b-button>
								</div>
							</b-col>
						</b-row>
					</b-card-footer>
				</b-card>
				<b-card v-else no-body>
					<b-card-body>
						<b-row>
							<b-col>
								<div class="text-center">
									<div class="text-color-rhapsody-in-blue fs-20 fw-600">
										{{ FormMSG(1687, 'Congratulation !') }}
									</div>
								</div>
							</b-col>
						</b-row>
						<b-row class="mt-3">
							<b-col>
								<div class="text-center">
									<div class="text-color-rhapsody-in-blue fs-20 fw-600">
										{{ FormMSG(1688, 'Your submission has been sent under the Job Request number') }} {{ jobRequestNumber }} .
									</div>
								</div>
							</b-col>
						</b-row>
						<b-row class="mt-3">
							<b-col>
								<div class="text-center">
									<div class="text-color-rhapsody-in-blue fs-20 fw-600">
										{{ FormMSG(1708, 'You can add a document to your Job request (like your deal memo descritpion) here:') }}
									</div>
								</div>
							</b-col>
						</b-row>
						<b-row class="mt-3 mb-2">
							<b-col
								:sm="filesToUpload.length > 0 ? 11 : 12"
								:md="filesToUpload.length > 0 ? 11 : 12"
								:lg="filesToUpload.length > 0 ? 11 : 12"
								:xl="filesToUpload.length > 0 ? 11 : 12"
							>
								<b-form-file
									v-model="filesToUpload"
									multiple
									:file-name-formatter="formatNameFiles"
									style="margin-bottom: 0 !important"
									:placeholder="FormMSG(888, 'Drag files to download')"
									@input="handleInputFiles"
								/>
							</b-col>
							<b-col v-if="filesToUpload.length > 0" sm="1" md="1" lg="1" xl="1" class="pl-0 pr-0">
								<b-button block variant="outline-danger" size="sm" @click="handleClickRemoveFilesSelected">
									<component :is="getLucideIcon('X')" :size="16" />
								</b-button>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col sm="12">
								<b-button block variant="primary" :disabled="filesToUpload.length === 0 || loadingSendDoc" @click="handleClickSend">
									<b-spinner v-if="loadingSendDoc" small />
									{{ FormMSG(1711, 'Send') }}
								</b-button>
							</b-col>
						</b-row>
						<b-row class="mt-3">
							<b-col>
								<div class="text-center">
									<div class="text-color-rhapsody-in-blue fs-20 fw-600">
										{{ FormMSG(1709, 'Once done, you can close this window.') }}
									</div>
								</div>
							</b-col>
						</b-row>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import StepOneForm from '../components/StepOneForm.vue';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import StepTwoForm from '../components/StepTwoForm.vue';
import StepThreeForm from '../components/StepThreeForm.vue';
import StepFourForm from '../components/StepFourForm.vue';
import _ from 'lodash';
import moment from 'moment';
import { store } from '@/store';
import { getUserByEmail } from '@/cruds/users.crud';
import { submitJobRequest } from '@/cruds/job-request.crud';

const INIT_DATA_STEP_TWO = {
	phone: '',
	firstName: null,
	name: null,
	email: '',
	nationality: null,
	language: 0,
	gender: null,
	birthDate: new Date(),
	placeOfBirth: null,
	birthCountry: null,

	nationalRegistryNumber: null,
	companyNumber: null,

	taxesPercentage: null,
	iban: null,
	bic: '',

	// ADDRESS
	country: 16965,
	internalSearch: '',
	street: null,
	number: null,
	zip: null,
	city: null,

	civilStatus: 0,
	spouseFirstName: null,
	spouseName: null,
	mariedOn: new Date(),
	spouseProfession: '',
	spouseHasSalary: false,
	numberOfDependentChild: 0,
	numberOfDisabledChild: 0,
	numberOfDependentPerson: 0,
	numberOfDisabledPerson: 0,

	personToContact: null,
	personToContactPhone: null,

	studyCurrently: false,
	studyLevel: 0,
	bankDetailXid: null,
	spouseBirthDate: new Date(),
	socSecNumber: ''
};

const INIT_DATA_STEP_THREE = {
	personalDataCountry: 16965,
	personalDataTax: 0,
	personalDataCompanyName: null,
	personalDataStreet: null,
	personalDataNumber: null,
	personalDataZip: null,
	personalDataCity: null,

	contactName: null,
	contactEmail: null,
	contactPhone: ''
};

const INIT_DATA_STEP_FOUR = {
	projectName: null,
	productionType: null,
	contractDepartment: null,
	contractFunction: null,
	contractStartDate: new Date(),
	contractEndDate: new Date(),
	contractDaysForeseen: 1,
	contractDailyRate: 0,
	contractDailyHours: '00:00',
	contractNote: '',
	workingDates: [],
	specifyWorkingDays: false,
	salaryType: 0
};

export default {
	name: 'MainJobRequest',

	mixins: [languageMessages, globalMixin],

	components: {
		StepFourForm,
		StepThreeForm,
		StepTwoForm,
		StepOneForm
	},

	data() {
		return {
			hasPassedStepTwo: false,
			currentStep: 1,
			loadingSubmit: false,
			submitted: false,
			jobRequestNumber: '',
			jobRequestId: 0,
			jobRequestToken: '',
			loadingSendDoc: false,
			filesToUpload: [],
			tmpFilesToUpload: [],

			followStepProgressed: {
				stepOneOk: false,
				stepTwoOk: false,
				stepThreeOk: false,
				stepFourOk: false
			},

			originalDataStepTwo: {
				..._.cloneDeep(INIT_DATA_STEP_TWO)
			},
			dataStepTwo: {
				..._.cloneDeep(INIT_DATA_STEP_TWO)
			},

			originalDataStepThree: {
				..._.cloneDeep(INIT_DATA_STEP_THREE)
			},

			dataStepThree: {
				..._.cloneDeep(INIT_DATA_STEP_THREE)
			},

			originalDataStepFour: {
				..._.cloneDeep(INIT_DATA_STEP_FOUR)
			},

			dataStepFour: {
				..._.cloneDeep(INIT_DATA_STEP_FOUR)
			}
		};
	},

	computed: {
		disableBtnNext() {
			if (this.currentStep === 1) {
				return !this.followStepProgressed.stepOneOk;
			} else if (this.currentStep === 2) {
				return !this.followStepProgressed.stepTwoOk;
			} else if (this.currentStep === 3) {
				return !this.followStepProgressed.stepThreeOk;
			} else if (this.currentStep === 4) {
				return !this.followStepProgressed.stepFourOk;
			}
		},
		titleLabel() {
			switch (this.currentStep) {
				case 1:
					return `${this.stepLabel} - ${this.FormMSG(64, 'Email verification')}`;
				case 2:
					return `${this.stepLabel} - ${this.FormMSG(65, 'Personal information')}`;
				case 3:
					return `${this.stepLabel} - ${this.FormMSG(66, 'Production company information')}`;
				case 4:
					return `${this.stepLabel} - ${this.FormMSG(67, 'Contract information')}`;
			}
		},
		stepLabel() {
			return `${this.FormMSG(68, 'Step')} ${this.currentStep}/4`;
		},
		showNextBtn() {
			if ([1, 2, 3].includes(this.currentStep)) {
				return true;
			}

			return false;
		}
	},

	methods: {
		async handleClickSend() {
			this.loadingSendDoc = true;
			for (let i = 0; i < this.filesToUpload.length; i++) {
				const element = this.filesToUpload[i];
				let formData = new FormData();

				formData.append('uploadimage', element);
				formData.append('fileName', element.name);
				formData.append('fileType', element.type);
				formData.append('parentType', 'job_request');
				formData.append('parentSubType', 'memo_deal');
				formData.append('parentId', this.jobRequestId);

				let urlStr = process.env.VUE_APP_GQL + '/upload';

				await this.$axios
					.$post(urlStr, formData, {
						headers: {
							Authorization: `Bearer ${this.jobRequestToken}`,
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((res) => {
						if (res) {
							this.createToastForMobile('Information', 'Document send with success', '', 'success');
						} else {
							this.createToastForMobile('Warning', 'Document not send', '', 'warning');
						}
					})
					.catch(() => {
						this.createToastForMobile('Error', 'There is an error', '', 'error');
					});
			}

			this.filesToUpload = [];
			this.tmpFilesToUpload = [];

			this.loadingSendDoc = false;
		},
		handleClickRemoveFilesSelected() {
			this.filesToUpload = [];
			this.tmpFilesToUpload = [];
		},
		handleInputFiles(files) {
			this.tmpFilesToUpload = _.concat(this.tmpFilesToUpload, files);

			this.filesToUpload = this.tmpFilesToUpload;
		},
		formatNameFiles(files) {
			let result = [];
			if (this.filesToUpload.length > 0) {
				for (let i = 0; i < this.filesToUpload.length; i++) {
					const element = this.filesToUpload[i];
					result.push(element.name);
				}
			}

			return result.join(', ');
		},
		actionIsInvalid() {
			this.createToastForMobile(this.FormMSG(891, 'Error'), this.FormMSG(892, 'Many fields is mandatory'), '', 'danger');

			const mainElement = document.querySelector('.app-body');

			mainElement.scrollTo({
				top: 0,
				behavior: 'smooth' // Use smooth scrolling if supported
			});
		},
		async handleClickSubmit() {
			const isInvalidSteFour = this.$refs['step-four-form'].isInvalid();

			if (!isInvalidSteFour) {
				this.loadingSubmit = true;
				const dataToSend = await this.prepareDataToSend();

				let contractWorkingDate = [];

				this.dataStepFour.workingDates.map((option) => {
					contractWorkingDate.push(option.date);
				});

				const result = await submitJobRequest(dataToSend, contractWorkingDate);
				this.jobRequestNumber = result.jobRequestNumber;
				this.jobRequestId = +result.id;
				this.jobRequestToken = result.token;

				this.submitted = true;
				this.loadingSubmit = false;
			} else {
				this.actionIsInvalid();
			}
		},
		getLabelCountry(countryValue) {
			let countries = this.FormMenu(8);

			if (typeof countryValue === 'string') return countryValue;

			let find = countries.find((option) => option.value === countryValue);

			return find.text;
		},
		getCountryKey(countryValue) {
			let countries = this.FormMenu(8);

			if (typeof countryValue === 'string') return countryValue;

			let find = countries.find((option) => option.value === countryValue);

			return +find.value || 0;
		},
		async insertFile() {
			if (!_.isNil(this.dataStepTwo.bankDetailXid)) {
				let formData = new FormData();
				let parentType = 'job_request_bank_detail';

				formData.append('uploadimage', this.dataStepTwo.bankDetailXid);
				formData.append('fileName', this.dataStepTwo.bankDetailXid.name);
				formData.append('fileType', this.dataStepTwo.bankDetailXid.type);
				formData.append('parentType', parentType);
				formData.append('parentId', 0);

				let urlStr = process.env.VUE_APP_GQL + '/upload';

				const xid = await this.$axios.$post(urlStr, formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'Content-Type': 'multipart/form-data'
					}
				});

				return xid;
			}

			return '';
		},
		async prepareDataToSend() {
			const xid = await this.insertFile();

			const endDate = moment(this.dataStepFour.contractEndDate).format('YYYY-MM-DD') + 'T23:59:59Z';

			return {
				function: this.dataStepFour.contractFunction,
				department: this.dataStepFour.contractDepartment,
				offerStatus: 0,
				invitationStatus: 0,
				userOnBoard: 0,
				specifyWorkingDays: this.dataStepFour.specifyWorkingDays,
				projectName: this.dataStepFour.projectName,
				productionType: this.dataStepFour.productionType,
				user: {
					firstName: this.dataStepTwo.firstName,
					name: this.dataStepTwo.name,
					email: this.dataStepTwo.email,
					phone: this.dataStepTwo.phone,
					nationality: this.dataStepTwo.nationality,
					language: this.dataStepTwo.language,
					gender: this.dataStepTwo.gender,
					birthDate: moment(this.dataStepTwo.birthDate).format(),
					placeOfBirth: this.dataStepTwo.placeOfBirth,
					nationalRegistryNumber: this.dataStepTwo.nationalRegistryNumber,
					companyNumber: this.dataStepTwo.companyNumber,
					taxesPercentage: +this.dataStepTwo.taxesPercentage,
					iban: this.dataStepTwo.iban,
					bic: this.dataStepTwo.bic,
					civilStatus: this.dataStepTwo.civilStatus,
					spouseFirstName: this.dataStepTwo.spouseFirstName,
					spouseBirthDate: this.dataStepTwo.spouseBirthDate,
					socSecNumber: this.dataStepTwo.socSecNumber,
					spouseName: this.dataStepTwo.spouseName,
					mariedOn: this.dataStepTwo.mariedOn,
					spouseProfession: this.dataStepTwo.spouseProfession,
					spouseHasSalary: this.dataStepTwo.spouseHasSalary,
					numberOfDependentChild: +this.dataStepTwo.numberOfDependentChild,
					numberOfDisabledChild: +this.dataStepTwo.numberOfDisabledChild,
					numberOfDependentPerson: +this.dataStepTwo.numberOfDependentPerson,
					numberOfDisabledPerson: +this.dataStepTwo.numberOfDisabledPerson,
					personToContact: this.dataStepTwo.personToContact,
					personToContactPhone: this.dataStepTwo.personToContactPhone,
					studyLevel: this.dataStepTwo.studyLevel,
					bankDetailXid: xid,
					birthCountry: this.dataStepTwo.birthCountry,
					address: {
						country: this.getLabelCountry(this.dataStepTwo.country),
						street: this.dataStepTwo.street,
						number: this.dataStepTwo.number,
						zip: this.dataStepTwo.zip,
						city: this.dataStepTwo.city,
						countryKey: this.getCountryKey(this.dataStepTwo.country)
					}
				},
				supplier: {
					name: this.dataStepThree.personalDataCompanyName,
					contactName: this.dataStepThree.contactName,
					email: this.dataStepThree.contactEmail,
					phone: this.dataStepThree.contactPhone,
					address: {
						country: this.getLabelCountry(this.dataStepThree.personalDataCountry),
						street: this.dataStepThree.personalDataStreet,
						number: this.dataStepThree.personalDataNumber,
						zip: this.dataStepThree.personalDataZip,
						city: this.dataStepThree.personalDataCity
					}
				},
				contract: {
					departmentName: this.dataStepFour.contractDepartment,
					functionName: this.dataStepFour.contractFunction,
					startDate: moment(this.dataStepFour.contractStartDate).format(),
					endDate: moment(endDate).format(),
					daysForeseen: +this.dataStepFour.contractDaysForeseen,
					dailyRate: parseFloat(this.dataStepFour.contractDailyRate),
					totMinPerDay: this.convertHoursToMinutes(this.dataStepFour.contractDailyHours),
					note: this.dataStepFour.contractNote,
					salaryType: this.dataStepFour.salaryType
				}
			};
		},
		handleStepFourFormChange({ data }) {
			this.dataStepFour = {
				...data
			};

			if (this.stepFourIsValid() === true) {
				this.followStepProgressed.stepFourOk = true;
			}
		},
		handleStepThreeFormChange({ data }) {
			this.dataStepThree = {
				...data
			};

			if (this.stepThreeIsValid() === true) {
				this.followStepProgressed.stepThreeOk = true;
			}
		},
		handleStepTwoFormChange({ data }) {
			this.dataStepTwo = {
				...data
			};
			
			if (this.stepTwoIsValid() === true) {
				this.followStepProgressed.stepTwoOk = true;
			}
		},
		stepTwoIsValid() {
			let cond =
				!_.isNil(this.dataStepTwo.firstName) &&
				!_.isNil(this.dataStepTwo.name) &&
				this.dataStepTwo.phone !== '' &&
				!_.isNil(this.dataStepTwo.nationality) &&
				!_.isNil(this.dataStepTwo.gender) &&
				!_.isNil(this.dataStepTwo.placeOfBirth) &&
				!_.isNil(this.dataStepTwo.nationalRegistryNumber) &&
				!_.isNil(this.dataStepTwo.taxesPercentage) &&
				!_.isNil(this.dataStepTwo.iban) &&
				'' + this.dataStepTwo.country !== '' &&
				!_.isNil(this.dataStepTwo.street) &&
				!_.isNil(this.dataStepTwo.number) &&
				!_.isNil(this.dataStepTwo.zip) &&
				!_.isNil(this.dataStepTwo.city);

			return cond;
		},
		stepThreeIsValid() {
			return (
				this.dataStepThree.personalDataCountry > 0 &&
				!_.isNil(this.dataStepThree.personalDataCompanyName) &&
				!_.isNil(this.dataStepThree.personalDataStreet) &&
				!_.isNil(this.dataStepThree.personalDataNumber) &&
				!_.isNil(this.dataStepThree.personalDataZip) &&
				!_.isNil(this.dataStepThree.personalDataCity) &&
				!_.isNil(this.dataStepThree.contactName) &&
				!_.isNil(this.dataStepThree.contactEmail)
			);
		},
		stepFourIsValid() {
			return (
				!_.isNil(this.dataStepFour.projectName) &&
				!_.isNil(this.dataStepFour.productionType) &&
				!_.isNil(this.dataStepFour.contractDepartment) &&
				!_.isNil(this.dataStepFour.contractFunction) &&
				this.dataStepFour.contractDaysForeseen > 0
			);
		},
		handleStepOneFormOk({ email }) {
			this.dataStepTwo.email = email;
			this.followStepProgressed.stepOneOk = true;
		},
		async handleClickNext() {
			try {
				if (this.currentStep === 1) {
					if (!this.hasPassedStepTwo) {
						const result = await getUserByEmail(this.dataStepTwo.email);

						if (!_.isNil(result)) {
							await this.setdataStepTwo(result);
						} else {
							this.originalDataStepTwo.email = this.dataStepTwo.email;
						}
					}

					this.hasPassedStepTwo = true;
					this.currentStep++;
				} else if (this.currentStep === 2) {
					const isInvalidStepTwo = this.$refs['step-two-form'].isInvalid();

					if (!isInvalidStepTwo) {
						this.currentStep++;
					} else {
						this.actionIsInvalid();
					}

					// this.currentStep++;
				} else if (this.currentStep === 3) {
					const isInvalidStepThree = this.$refs['step-three-form'].isInvalid();

					if (!isInvalidStepThree) {
						this.currentStep++;
					} else {
						this.actionIsInvalid();
					}

					// this.currentStep++;
				}

				// this.currentStep++;
			} catch (e) {
				console.log(e);
			}
		},
		setdataStepTwo(payload) {
			this.originalDataStepTwo.phone = payload.phone;
			this.originalDataStepTwo.firstName = payload.firstName;
			this.originalDataStepTwo.name = payload.name;
			this.originalDataStepTwo.email = this.dataStepTwo.email;
			this.originalDataStepTwo.nationality = payload.nationality;
			this.originalDataStepTwo.language = payload.language;
			this.originalDataStepTwo.gender = payload.gender;
			this.originalDataStepTwo.birthDate = payload.birthDate;
			this.originalDataStepTwo.placeOfBirth = payload.placeOfBirth;
			this.originalDataStepTwo.nationalRegistryNumber = payload.nationalRegistryNumber;
			this.originalDataStepTwo.companyNumber = payload.companyNumber;
			this.originalDataStepTwo.taxesPercentage = payload.taxesPercentage;
			this.originalDataStepTwo.iban = payload.iban;
			this.originalDataStepTwo.bic = payload.bic;
			this.originalDataStepTwo.country = payload.address.country;
			this.originalDataStepTwo.street = payload.address.street;
			this.originalDataStepTwo.number = payload.address.number;
			this.originalDataStepTwo.zip = payload.address.zip;
			this.originalDataStepTwo.city = payload.address.city;
			this.originalDataStepTwo.civilStatus = payload.civilStatus;
			this.originalDataStepTwo.spouseFirstName = payload.spouseFirstName;
			this.originalDataStepTwo.spouseName = payload.spouseName;
			this.originalDataStepTwo.spouseBirthDate = payload.spouseBirthDate;
			this.originalDataStepTwo.socSecNumber = payload.socSecNumber;
			this.originalDataStepTwo.spouseProfession = payload.spouseProfession;
			this.originalDataStepTwo.spouseHasSalary = payload.spouseHasSalary;
			this.originalDataStepTwo.numberOfDependentChild = payload.numberOfDependentChild;
			this.originalDataStepTwo.numberOfDisabledChild = payload.numberOfDisabledChild;
			this.originalDataStepTwo.numberOfDependentPerson = payload.numberOfDependentPerson;
			this.originalDataStepTwo.numberOfDisabledPerson = payload.numberOfDisabledPerson;
			this.originalDataStepTwo.personToContact = payload.personToContact;
			this.originalDataStepTwo.personToContactPhone = payload.personToContactPhone;
			this.originalDataStepTwo.studyLevel = payload.studyLevel;
			this.originalDataStepTwo.bankDetailXid = payload.bankDetailXid;
			this.originalDataStepTwo.mariedOn = payload.mariedOn;
			this.originalDataStepTwo.birthCountry = payload.birthCountry;

			this.dataStepTwo = _.cloneDeep(this.originalDataStepTwo);
		},
		handleClickPrevious() {
			this.currentStep--;
		},
		handleClickDot(currentStep) {
			if (currentStep === 1 && this.followStepProgressed.stepOneOk) {
				this.currentStep = currentStep;
			}
			if (currentStep === 2 && this.followStepProgressed.stepOneOk && this.followStepProgressed.stepTwoOk) {
				this.currentStep = currentStep;
			}
			if (currentStep === 3 && this.followStepProgressed.stepOneOk && this.followStepProgressed.stepTwoOk && this.followStepProgressed.stepThreeOk) {
				this.currentStep = currentStep;
			}
			if (
				currentStep === 3 &&
				this.followStepProgressed.stepOneOk &&
				this.followStepProgressed.stepTwoOk &&
				this.followStepProgressed.stepThreeOk &&
				this.followStepProgressed.stepFourOk
			) {
				this.currentStep = currentStep;
			}
		}
	}
};
</script>

<style scoped lang="scss">
.dot-step {
	width: 20px;
	height: 20px;
	background-color: #dfdfdf;
	border-radius: 50%;
	cursor: pointer;

	@media screen and (max-width: 576px) {
		width: 10px;
		height: 10px;
	}

	&.finished {
		background-color: #00a09c;
	}
	&.current {
		background-color: #47c7bf;
	}
}
</style>
