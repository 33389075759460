var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.stateError.show
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-alert",
                    {
                      attrs: { show: "", variant: "danger", dismissible: "" },
                      on: { dismissed: _vm.handleDismissedError },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.stateError.msg) + "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "9", lg: "9", xl: "9" } },
            [
              _c(
                "b-form-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("custom-label-mandatory", {
                            attrs: {
                              label: _vm.FormMSG(15, "Your email address"),
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c("b-form-input", {
                    attrs: {
                      state: _vm.getStateValidationField("email"),
                      placeholder: _vm.FormMSG(841, "Enter your email"),
                      autocomplete: "email",
                      disabled: _vm.emailValidated,
                    },
                    model: {
                      value: _vm.$v.email.$model,
                      callback: function ($$v) {
                        _vm.$set(_vm.$v.email, "$model", $$v)
                      },
                      expression: "$v.email.$model",
                    },
                  }),
                  !_vm.$v.email.email &&
                  _vm.getStateValidationField("email") !== null
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(844, "Enter an email valid")) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  !_vm.$v.email.required &&
                  _vm.getStateValidationField("email") !== null
                    ? _c("div", { staticClass: "invalid-feedback" }, [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.FormMSG(843, "Email required")) +
                            "\n\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.emailValidated
            ? _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-end",
                  class: {
                    "pb-3": !_vm.$v.email.$error,
                    "pb-5": _vm.$v.email.$error,
                  },
                  attrs: { sm: "12", md: "3", lg: "3", xl: "3" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        block: "",
                        disabled:
                          _vm.disableVerifyEmail || _vm.loadingFor.verifyEmail,
                      },
                      on: { click: _vm.verifyEmail },
                    },
                    [
                      _vm.loadingFor.verifyEmail
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _vm._e(),
                      _vm.dataToCheck.emailCodeValidation !== 0 &&
                      !_vm.loadingFor.verifyEmail
                        ? _c("span", [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(45, "Resend code")) +
                                "\n\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                      !_vm.loadingFor.verifyEmail &&
                      _vm.dataToCheck.emailCodeValidation === 0
                        ? _c("span", [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(46, "Verify email")) +
                                "\n\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                      _vm.loadingFor.verifyEmail
                        ? _c("span", [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(747, "Check ")) +
                                "\n\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showVerificationMail
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "9", md: "9", lg: "9", xl: "9" } },
                [
                  _c(
                    "b-form-group",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("custom-label-mandatory", {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      17,
                                      "Email verification code"
                                    ),
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        582180666
                      ),
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          state:
                            _vm.getStateValidationField(
                              "emailCodeValidation"
                            ) || _vm.stateError.notSameEmailCodeValidation,
                          placeholder: _vm.FormMSG(852, "eg: 1234"),
                          disabled: _vm.emailValidated,
                        },
                        on: { input: _vm.handleInputEmailCodeValidation },
                        model: {
                          value: _vm.$v.emailCodeValidation.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.emailCodeValidation, "$model", $$v)
                          },
                          expression: "$v.emailCodeValidation.$model",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.emailValidated
                ? _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-end pb-3",
                      attrs: { sm: "3", md: "3", lg: "3", xl: "3" },
                    },
                    [
                      _c(
                        "button",
                        { staticClass: "btn-transparent text-success" },
                        [
                          _c(_vm.getLucideIcon("Check"), {
                            tag: "component",
                            attrs: { size: 20 },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(1282, "Validation code"),
            "ok-variant": "success",
            "ok-only": "",
          },
          on: {
            ok: function ($event) {
              _vm.showModalInfoCode = false
            },
          },
          model: {
            value: _vm.showModalInfoCode,
            callback: function ($$v) {
              _vm.showModalInfoCode = $$v
            },
            expression: "showModalInfoCode",
          },
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.messageModalInfoCode) + "\n\t")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }