var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.stateError.show
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-alert",
                    {
                      attrs: { show: "", variant: "danger", dismissible: "" },
                      on: { dismissed: _vm.handleDismissed },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" + _vm._s(_vm.stateError.msg) + "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("h2", { staticClass: "text-mobile-sub-title sub-title ml-0 mb-2" }, [
        _vm._v(_vm._s(_vm.FormMSG(256, "Personal data"))),
      ]),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(257, "First name") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              placeholder: _vm.FormMSG(1987, "Enter first name"),
              state: _vm.getStateValidationField("dataToSend.firstName"),
            },
            model: {
              value: _vm.$v.dataToSend.firstName.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.firstName, "$model", $$v)
              },
              expression: "$v.dataToSend.firstName.$model",
            },
          }),
          _vm.$v.dataToSend.firstName.$error &&
          _vm.getStateValidationField("dataToSend.firstName") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1988, "Please enter first name")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(258, "Last name") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              placeholder: _vm.FormMSG(1010, "Enter last name"),
              state: _vm.getStateValidationField("dataToSend.name"),
            },
            model: {
              value: _vm.$v.dataToSend.name.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.name, "$model", $$v)
              },
              expression: "$v.dataToSend.name.$model",
            },
          }),
          _vm.$v.dataToSend.name.$error &&
          _vm.getStateValidationField("dataToSend.name") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(3011, "Please enter last name")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(259, "Email address") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: { value: _vm.dataToSend.email, disabled: "" },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(987, "Phone") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "d-flex" }, [
            _c(
              "div",
              [
                _c(
                  "b-input-group-prepend",
                  { staticStyle: { height: "100%" } },
                  [
                    _c(
                      "b-input-group-text",
                      {
                        staticStyle: { "border-radius": "0.25rem 0 0 0.25rem" },
                      },
                      [
                        _c(_vm.getLucideIcon("Phone"), {
                          tag: "component",
                          attrs: { color: "#4d4e4f", size: 16 },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "w-100" }, [
              _vm.phoneInited
                ? _c(
                    "div",
                    { staticClass: "w-100" },
                    [
                      _c("VuePhoneNumberInput", {
                        attrs: {
                          "error-color": "#EA4E2C",
                          "valid-color": "#00AF75",
                          size: "sm",
                          translations: _vm.optionsTranslation,
                          disabled: _vm.phoneValidated,
                          "fetch-country": "",
                        },
                        on: { update: _vm.handleUpdatePhoneNumber },
                        model: {
                          value: _vm.$v.phone.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.phone, "$model", $$v)
                          },
                          expression: "$v.phone.$model",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("VuePhoneNumberInput", {
                        attrs: {
                          "error-color": "#EA4E2C",
                          "valid-color": "#00AF75",
                          size: "sm",
                          translations: _vm.optionsTranslation,
                          disabled: _vm.phoneValidated,
                          "default-country-code": "BE",
                        },
                        on: { update: _vm.handleUpdatePhoneNumber },
                        model: {
                          value: _vm.$v.phone.$model,
                          callback: function ($$v) {
                            _vm.$set(_vm.$v.phone, "$model", $$v)
                          },
                          expression: "$v.phone.$model",
                        },
                      }),
                    ],
                    1
                  ),
            ]),
          ]),
          _vm.$v.phone.$error && _vm.getStateValidationField("phone") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(892, "Phone is required")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ]
      ),
      !_vm.phoneValidated
        ? _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "b-col",
                {
                  class: {
                    "d-flex align-items-end pb-3": _vm.showVerificationPhone,
                  },
                  attrs: { cols: !_vm.showVerificationPhone ? 12 : 4 },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        variant: "primary",
                        block: "",
                        disabled:
                          _vm.disableVerifyPhone || _vm.loadingFor.verifyPhone,
                      },
                      on: { click: _vm.verifyPhone },
                    },
                    [
                      _vm.loadingFor.verifyPhone
                        ? _c("b-spinner", { attrs: { small: "" } })
                        : _vm._e(),
                      _c("span", [
                        _vm._v(
                          "\n\t\t\t\t\t" +
                            _vm._s(_vm.labelPhoneAction) +
                            "\n\t\t\t\t"
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showVerificationPhone
                ? _c(
                    "b-col",
                    { attrs: { cols: 8 } },
                    [
                      _c(
                        "b-form-group",
                        {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("custom-label-mandatory", {
                                      attrs: {
                                        label: _vm.FormMSG(
                                          597,
                                          "Phone verification code"
                                        ),
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4129415607
                          ),
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              placeholder: "ex: 1234",
                              state: _vm.stateError.phoneCodeValidation,
                              disabled: _vm.validateCodePhone,
                            },
                            on: { input: _vm.handleInputPhoneCodeValidation },
                            model: {
                              value: _vm.phoneCodeValidation,
                              callback: function ($$v) {
                                _vm.phoneCodeValidation = $$v
                              },
                              expression: "phoneCodeValidation",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(261, "Nationality") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              placeholder: _vm.FormMSG(1012, "Enter nationality"),
              state: _vm.getStateValidationField("dataToSend.nationality"),
            },
            model: {
              value: _vm.$v.dataToSend.nationality.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.nationality, "$model", $$v)
              },
              expression: "$v.dataToSend.nationality.$model",
            },
          }),
          _vm.$v.dataToSend.nationality.$error &&
          _vm.getStateValidationField("dataToSend.nationality") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(2013, "Please enter nationality")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(262, "Language") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-select", {
            attrs: { options: _vm.optionsForLanguages },
            model: {
              value: _vm.dataToSend.language,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "language", $$v)
              },
              expression: "dataToSend.language",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(263, "Gender") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-select", {
            attrs: {
              options: _vm.menuGenders,
              state: _vm.getStateValidationField("dataToSend.gender"),
            },
            model: {
              value: _vm.$v.dataToSend.gender.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.gender, "$model", $$v)
              },
              expression: "$v.dataToSend.gender.$model",
            },
          }),
          _vm.$v.dataToSend.gender.$error &&
          _vm.getStateValidationField("dataToSend.gender") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1674, "Please select a gender")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(265, "Birthdate") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("v-date-picker", {
            attrs: {
              value: _vm.$v.dataToSend.birthDate.$model,
              masks: { input: "DD/MM/YYYY" },
            },
            on: { input: _vm.handleInputBirthDate },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ inputValue, togglePopover }) {
                  return [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          staticClass: "bg-white",
                          attrs: { value: inputValue, readonly: "" },
                          on: { click: togglePopover },
                        }),
                        _c(
                          "b-input-group-append",
                          [
                            _c(
                              "b-input-group-text",
                              {
                                staticClass: "cursor-pointer",
                                on: { click: togglePopover },
                              },
                              [
                                _c(_vm.getLucideIcon("Calendar"), {
                                  tag: "component",
                                  attrs: { color: "#06263E", size: 16 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.$v.dataToSend.birthDate.$error &&
          _vm.getStateValidationField("dataToSend.birthDate")
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1563, "Please enter birthdate")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(
                        266,
                        "Place of birth(City and Country)"
                      ),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.placeOfBirth"),
              placeholder: _vm.FormMSG(1698, "Enter place of birth"),
            },
            model: {
              value: _vm.$v.dataToSend.placeOfBirth.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.placeOfBirth, "$model", $$v)
              },
              expression: "$v.dataToSend.placeOfBirth.$model",
            },
          }),
          _vm.$v.dataToSend.placeOfBirth.$error &&
          _vm.getStateValidationField("dataToSend.placeOfBirth") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(2699, "Please enter place of birth")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(1266, "Country of birth") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.placeOfBirth"),
              placeholder: _vm.FormMSG(1698, "Enter place of birth"),
            },
            model: {
              value: _vm.$v.dataToSend.birthCountry.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.birthCountry, "$model", $$v)
              },
              expression: "$v.dataToSend.birthCountry.$model",
            },
          }),
          _vm.$v.dataToSend.birthCountry.$error &&
          _vm.getStateValidationField("dataToSend.birthCountry") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(2851, "Please enter country of birth")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("h2", { staticClass: "text-mobile-sub-title sub-title ml-0 mb-2" }, [
        _vm._v(_vm._s(_vm.FormMSG(270, "Identification"))),
      ]),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(271, "National registration number"),
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("InputMask", {
            staticClass: "form-control",
            attrs: {
              id: "nationalRegistryNumberJobRequest",
              mask: "99.99.99-999-99",
              placeholder: _vm.FormMSG(
                1700,
                "Enter national registration number"
              ),
            },
            model: {
              value: _vm.$v.dataToSend.nationalRegistryNumber.$model,
              callback: function ($$v) {
                _vm.$set(
                  _vm.$v.dataToSend.nationalRegistryNumber,
                  "$model",
                  $$v
                )
              },
              expression: "$v.dataToSend.nationalRegistryNumber.$model",
            },
          }),
          _vm.$v.dataToSend.nationalRegistryNumber.$error &&
          _vm.getStateValidationField("dataToSend.nationalRegistryNumber")
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm.FormMSG(
                        1701,
                        "Please enter national registration number"
                      )
                    ) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(2720, "Company number"),
                      "not-required": "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: { placeholder: _vm.FormMSG(1703, "Enter company number") },
            model: {
              value: _vm.dataToSend.companyNumber,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "companyNumber", $$v)
              },
              expression: "dataToSend.companyNumber",
            },
          }),
        ],
        1
      ),
      _c("h2", { staticClass: "text-mobile-sub-title sub-title ml-0 mb-2" }, [
        _vm._v(_vm._s(_vm.FormMSG(2730, "Financial information"))),
      ]),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(275, "Tax withholding") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "b-input-group",
            [
              _c("b-form-input", {
                attrs: {
                  state: _vm.getStateValidationField(
                    "dataToSend.taxesPercentage"
                  ),
                  placeholder: _vm.FormMSG(1705, "Enter tax with holding"),
                },
                model: {
                  value: _vm.$v.dataToSend.taxesPercentage.$model,
                  callback: function ($$v) {
                    _vm.$set(_vm.$v.dataToSend.taxesPercentage, "$model", $$v)
                  },
                  expression: "$v.dataToSend.taxesPercentage.$model",
                },
              }),
              _c(
                "b-input-group-append",
                [
                  _c(
                    "b-input-group-text",
                    { staticClass: "cursor-pointer" },
                    [
                      _c(_vm.getLucideIcon("Percent"), {
                        tag: "component",
                        attrs: { color: "#06263E", size: 16 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.$v.dataToSend.taxesPercentage.$error &&
          _vm.getStateValidationField("dataToSend.taxesPercentage")
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1706, "Please enter tax with holding")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(279, "Iban") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.iban"),
              placeholder: _vm.FormMSG(1707, "Enter iban"),
            },
            model: {
              value: _vm.$v.dataToSend.iban.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.iban, "$model", $$v)
              },
              expression: "$v.dataToSend.iban.$model",
            },
          }),
          _vm.$v.dataToSend.iban.$error &&
          _vm.getStateValidationField("dataToSend.iban")
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1708, "Please enter iban")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: _vm.FormMSG(280, "Bic (if not in Belgium)") } },
        [
          _c("b-form-input", {
            attrs: { placeholder: _vm.FormMSG(1709, "Enter Bic") },
            model: {
              value: _vm.dataToSend.bic,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "bic", $$v)
              },
              expression: "dataToSend.bic",
            },
          }),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              attrs: {
                sm: "12",
                md: _vm.imageInDb && _vm.imageInDb !== "" ? 10 : 12,
                lg: _vm.imageInDb && _vm.imageInDb !== "" ? 10 : 12,
                xl: _vm.imageInDb && _vm.imageInDb !== "" ? 10 : 12,
              },
            },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.FormMSG(
                      281,
                      "Bank detail document (in not in Belgium)"
                    ),
                  },
                },
                [
                  _c("b-form-file", {
                    ref: "accomodationFile",
                    staticClass: "mb-2",
                    attrs: {
                      accept: _vm.supportedMimeTypes,
                      placeholder: _vm.FormMSG(
                        63,
                        "Choose a file or drop it here ..."
                      ),
                      "browse-text": _vm.FormMSG(64, "Browse"),
                      "drop-placeholder": _vm.FormMSG(65, "Drop file here ..."),
                    },
                    on: { change: _vm.handleChangeFileToUploaded },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.imageInDb && _vm.imageInDb !== ""
            ? _c("b-col", { attrs: { sm: "12", md: "2", lg: "2", xl: "2" } }, [
                _c(
                  "div",
                  {
                    staticClass: "w-100 text-center",
                    style: _vm.$screen.width <= 576 ? "" : "padding-top: 22px",
                  },
                  [
                    _c(
                      "b-button-group",
                      { attrs: { tag: "div" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "outline-primary" },
                            on: { click: _vm.handleClickViewImage },
                          },
                          [
                            _c(_vm.getLucideIcon("Eye"), {
                              tag: "component",
                              attrs: { size: 16 },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "danger" },
                            on: { click: _vm.handleClickRemoveImage },
                          },
                          [
                            _c(_vm.getLucideIcon("Trash2"), {
                              tag: "component",
                              attrs: { size: 16 },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("h2", { staticClass: "text-mobile-sub-title sub-title ml-0 mb-2" }, [
        _vm._v(_vm._s(_vm.FormMSG(1517, "Address"))),
      ]),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(285, "Country") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("v-select", {
            attrs: {
              options: _vm.countries,
              label: "text",
              clearable: false,
              reduce: (option) => option.value,
            },
            on: { input: _vm.getCountry },
            model: {
              value: _vm.dataToSend.country,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "country", $$v)
              },
              expression: "dataToSend.country",
            },
          }),
        ],
        1
      ),
      _c("google-auto-complete", {
        ref: "google-search-address",
        attrs: {
          label: _vm.FormMSG(286, "Internet search"),
          country: _vm.dataToSend.internalSearch,
        },
        on: { placechanged: (item) => _vm.handleAutoComplete(item) },
      }),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(287, "Street") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.street"),
              placeholder: _vm.FormMSG(1589, "Enter street"),
            },
            model: {
              value: _vm.$v.dataToSend.street.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.street, "$model", $$v)
              },
              expression: "$v.dataToSend.street.$model",
            },
          }),
          _vm.$v.dataToSend.street.$error &&
          _vm.getStateValidationField("dataToSend.street") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1547, "Please enter street")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(288, "Number") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.number"),
              placeholder: _vm.FormMSG(4781, "Enter number"),
            },
            model: {
              value: _vm.$v.dataToSend.number.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.number, "$model", $$v)
              },
              expression: "$v.dataToSend.number.$model",
            },
          }),
          _vm.$v.dataToSend.number.$error &&
          _vm.getStateValidationField("dataToSend.number") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(4782, "Please enter number")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(289, "Postal code") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.zip"),
              placeholder: _vm.FormMSG(1478, "Enter postal code"),
            },
            model: {
              value: _vm.$v.dataToSend.zip.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.zip, "$model", $$v)
              },
              expression: "$v.dataToSend.zip.$model",
            },
          }),
          _vm.$v.dataToSend.zip.$error &&
          _vm.getStateValidationField("dataToSend.zip") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1456, "Please enter postal code")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(291, "City") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.city"),
              placeholder: _vm.FormMSG(2547, "Enter city"),
            },
            model: {
              value: _vm.$v.dataToSend.city.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.city, "$model", $$v)
              },
              expression: "$v.dataToSend.city.$model",
            },
          }),
          _vm.$v.dataToSend.city.$error &&
          _vm.getStateValidationField("dataToSend.city") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(2548, "Please enter city")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        [
          _c("label", { attrs: { for: "state" } }, [
            _vm._v(_vm._s(_vm.FormMSG(2002, "Social Security Number")) + " "),
          ]),
          _c("b-form-input", {
            attrs: {
              placeholder: _vm.FormMSG(
                2003,
                "Enter your Social Security Number"
              ),
              maxlength: "15",
            },
            on: {
              input: _vm.handleSocSecNumberChange,
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.dataToSend.socSecNumber,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "socSecNumber", $$v)
              },
              expression: "dataToSend.socSecNumber",
            },
          }),
          _vm.isSocSecNumberWarning
            ? _c("div", { staticClass: "warning-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm.FormMSG(
                        8979997,
                        "The social security number seems not valid"
                      )
                    ) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("h2", { staticClass: "text-mobile-sub-title sub-title ml-0 mb-2" }, [
        _vm._v(_vm._s(_vm.FormMSG(293, "Family"))),
      ]),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(294, "Civil status") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-select", {
            attrs: { options: _vm.optionsForCivilStatus },
            on: { change: _vm.handleChangeCivilStatus },
            model: {
              value: _vm.dataToSend.civilStatus,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "civilStatus", $$v)
              },
              expression: "dataToSend.civilStatus",
            },
          }),
        ],
        1
      ),
      _vm.showSpouseInfo
        ? _c(
            "b-form-group",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("custom-label-mandatory", {
                          attrs: {
                            label: _vm.FormMSG(295, "Spouse first name"),
                            "not-required": "",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3186768751
              ),
            },
            [
              _c("b-form-input", {
                attrs: {
                  placeholder: _vm.FormMSG(1584, "Enter spouse first name"),
                },
                model: {
                  value: _vm.dataToSend.spouseFirstName,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataToSend, "spouseFirstName", $$v)
                  },
                  expression: "dataToSend.spouseFirstName",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showSpouseInfo
        ? _c(
            "b-form-group",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("custom-label-mandatory", {
                          attrs: {
                            label: _vm.FormMSG(296, "Spouse last name"),
                            "not-required": "",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2302822908
              ),
            },
            [
              _c("b-form-input", {
                attrs: {
                  placeholder: _vm.FormMSG(1586, "Enter spouse last name"),
                },
                model: {
                  value: _vm.dataToSend.spouseName,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataToSend, "spouseName", $$v)
                  },
                  expression: "dataToSend.spouseName",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showSpouseInfo
        ? _c(
            "b-form-group",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("custom-label-mandatory", {
                          attrs: {
                            label: _vm.FormMSG(297, "Wedding date"),
                            "not-required": "",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                3399522137
              ),
            },
            [
              _c("v-date-picker", {
                attrs: {
                  value: _vm.dataToSend.mariedOn,
                  masks: { input: "DD/MM/YYYY" },
                },
                on: { input: _vm.handleInputWeddingDate },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ inputValue, togglePopover }) {
                        return [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                staticClass: "bg-white",
                                attrs: { value: inputValue, readonly: "" },
                                on: { click: togglePopover },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass: "cursor-pointer",
                                      on: { click: togglePopover },
                                    },
                                    [
                                      _c(_vm.getLucideIcon("Calendar"), {
                                        tag: "component",
                                        attrs: { color: "#06263E", size: 16 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  665940656
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showSpouseInfo
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.FormMSG(298, "Spouse profession") } },
            [
              _c("b-form-input", {
                attrs: {
                  placeholder: _vm.FormMSG(847, "Enter spouse profession"),
                },
                model: {
                  value: _vm.dataToSend.spouseProfession,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataToSend, "spouseProfession", $$v)
                  },
                  expression: "dataToSend.spouseProfession",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showSpouseInfo
        ? _c(
            "b-form-group",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("custom-label-mandatory", {
                          attrs: {
                            label: _vm.FormMSG(300, "Spouse birth date"),
                            "not-required": "",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1392574670
              ),
            },
            [
              _c("v-date-picker", {
                attrs: {
                  value: _vm.dataToSend.spouseBirthDate,
                  masks: { input: "DD/MM/YYYY" },
                },
                on: { input: _vm.handleInputSpouseBirthDate },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function ({ inputValue, togglePopover }) {
                        return [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                staticClass: "bg-white",
                                attrs: { value: inputValue, readonly: "" },
                                on: { click: togglePopover },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-input-group-text",
                                    {
                                      staticClass: "cursor-pointer",
                                      on: { click: togglePopover },
                                    },
                                    [
                                      _c(_vm.getLucideIcon("Calendar"), {
                                        tag: "component",
                                        attrs: { color: "#06263E", size: 16 },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  665940656
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showSpouseInfo
        ? _c(
            "b-form-group",
            { attrs: { label: _vm.FormMSG(299, "Spouse has salary") } },
            [
              _c("b-form-checkbox", {
                attrs: { switch: "", size: "lg" },
                model: {
                  value: _vm.dataToSend.spouseHasSalary,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataToSend, "spouseHasSalary", $$v)
                  },
                  expression: "dataToSend.spouseHasSalary",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(301, "Number of dependent child"),
                      "not-required": "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: { type: "number", step: "1", min: "0", placeholder: "0" },
            model: {
              value: _vm.dataToSend.numberOfDependentChild,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "numberOfDependentChild", $$v)
              },
              expression: "dataToSend.numberOfDependentChild",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(1302, "Number of disabled child"),
                      "not-required": "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: { type: "number", step: "1", min: "0", placeholder: "0" },
            model: {
              value: _vm.dataToSend.numberOfDisabledChild,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "numberOfDisabledChild", $$v)
              },
              expression: "dataToSend.numberOfDisabledChild",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(303, "Number of dependent person"),
                      "not-required": "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: { type: "number", step: "1", min: "0", placeholder: "0" },
            model: {
              value: _vm.dataToSend.numberOfDependentPerson,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "numberOfDependentPerson", $$v)
              },
              expression: "dataToSend.numberOfDependentPerson",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(304, "Number of disabled person "),
                      "not-required": "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: { type: "number", step: "1", min: "0", placeholder: "0" },
            model: {
              value: _vm.dataToSend.numberOfDisabledPerson,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "numberOfDisabledPerson", $$v)
              },
              expression: "dataToSend.numberOfDisabledPerson",
            },
          }),
        ],
        1
      ),
      _c("h2", { staticClass: "text-mobile-sub-title sub-title ml-0 mb-2" }, [
        _vm._v(_vm._s(_vm.FormMSG(305, "Emergency contact"))),
      ]),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(306, "Person to contact name"),
                      "not-required": "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              placeholder: _vm.FormMSG(1689, "Enter person to contact name"),
            },
            model: {
              value: _vm.dataToSend.personToContact,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "personToContact", $$v)
              },
              expression: "dataToSend.personToContact",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(307, "Person to contact phone"),
                      "not-required": "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              placeholder: _vm.FormMSG(1690, "Enter person to contact phone"),
            },
            model: {
              value: _vm.dataToSend.personToContactPhone,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "personToContactPhone", $$v)
              },
              expression: "dataToSend.personToContactPhone",
            },
          }),
        ],
        1
      ),
      _c("h2", { staticClass: "text-mobile-sub-title sub-title ml-0 mb-2" }, [
        _vm._v(_vm._s(_vm.FormMSG(308, "Study"))),
      ]),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(1307, "Are you currently a student ?"),
                      "not-required": "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-checkbox", {
            attrs: { switch: "", size: "lg" },
            on: { change: _vm.handleChangeStudyCurrently },
            model: {
              value: _vm.dataToSend.studyCurrently,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "studyCurrently", $$v)
              },
              expression: "dataToSend.studyCurrently",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(311, "Study level"),
                      "not-required": "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-select", {
            attrs: { options: _vm.studyLevelOptions },
            model: {
              value: _vm.dataToSend.studyLevel,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "studyLevel", $$v)
              },
              expression: "dataToSend.studyLevel",
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-success",
          attrs: {
            "header-class": "header-class-modal-doc-package",
            title: _vm.FormMSG(1282, "Validation code"),
            "ok-variant": "success",
            "ok-only": "",
          },
          on: {
            ok: function ($event) {
              _vm.showModalInfoCode = false
            },
          },
          model: {
            value: _vm.showModalInfoCode,
            callback: function ($$v) {
              _vm.showModalInfoCode = $$v
            },
            expression: "showModalInfoCode",
          },
        },
        [_vm._v("\n\t\t" + _vm._s(_vm.messageModalInfoCode) + "\n\t")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }