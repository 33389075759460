<template>
	<div>
		<b-row v-if="stateError.show">
			<b-col>
				<b-alert show variant="danger" dismissible @dismissed="handleDismissedError">
					{{ stateError.msg }}
				</b-alert>
			</b-col>
		</b-row>
		<b-row>
			<b-col sm="12" md="9" lg="9" xl="9">
				<b-form-group>
					<template #label>
						<custom-label-mandatory :label="FormMSG(15, 'Your email address')" />
					</template>
					<b-form-input
						v-model="$v.email.$model"
						:state="getStateValidationField('email')"
						:placeholder="FormMSG(841, 'Enter your email')"
						autocomplete="email"
						:disabled="emailValidated"
					/>
					<div v-if="!$v.email.email && getStateValidationField('email') !== null" class="invalid-feedback">
						{{ FormMSG(844, 'Enter an email valid') }}
					</div>
					<div v-if="!$v.email.required && getStateValidationField('email') !== null" class="invalid-feedback">
						{{ FormMSG(843, 'Email required') }}
					</div>
				</b-form-group>
			</b-col>
			<b-col
				v-if="!emailValidated"
				sm="12"
				md="3"
				lg="3"
				xl="3"
				class="d-flex align-items-end"
				:class="{ 'pb-3': !$v.email.$error, 'pb-5': $v.email.$error }"
			>
				<b-button variant="primary" block :disabled="disableVerifyEmail || loadingFor.verifyEmail" @click="verifyEmail">
					<b-spinner v-if="loadingFor.verifyEmail" small />
					<span v-if="dataToCheck.emailCodeValidation !== 0 && !loadingFor.verifyEmail">
						{{ FormMSG(45, 'Resend code') }}
					</span>
					<span v-if="!loadingFor.verifyEmail && dataToCheck.emailCodeValidation === 0">
						{{ FormMSG(46, 'Verify email') }}
					</span>
					<span v-if="loadingFor.verifyEmail">
						{{ FormMSG(747, 'Check ') }}
					</span>
				</b-button>
			</b-col>
		</b-row>
		<b-row v-if="showVerificationMail">
			<b-col sm="9" md="9" lg="9" xl="9">
				<b-form-group>
					<template #label>
						<custom-label-mandatory :label="FormMSG(17, 'Email verification code')" />
					</template>
					<b-form-input
						v-model="$v.emailCodeValidation.$model"
						:state="getStateValidationField('emailCodeValidation') || stateError.notSameEmailCodeValidation"
						:placeholder="FormMSG(852, 'eg: 1234')"
						:disabled="emailValidated"
						@input="handleInputEmailCodeValidation"
					/>
				</b-form-group>
			</b-col>
			<b-col v-if="emailValidated" sm="3" md="3" lg="3" xl="3" class="d-flex align-items-end pb-3">
				<button class="btn-transparent text-success">
					<component :is="getLucideIcon('Check')" :size="20" />
				</button>
			</b-col>
		</b-row>

		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(1282, 'Validation code')"
			class="modal-success"
			v-model="showModalInfoCode"
			@ok="showModalInfoCode = false"
			ok-variant="success"
			ok-only
		>
			{{ messageModalInfoCode }}
		</b-modal>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { emailValidationCode, checkLicenseEmailValidationCode } from '@/cruds/registration.crud';
import CustomLabelMandatory from './CustomLabelMandatory.vue';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
	name: 'StepOneForm',

	mixins: [languageMessages, validationMixin, globalMixin],

	data() {
		return {
			email: null,
			emailCodeValidation: null,
			emailValidated: false,
			emailValidationProcess: 0,

			loadingFor: {
				verifyEmail: false
			},

			dataToCheck: {
				email: '',
				emailCodeValidation: 0,
				licenseId: ''
			},

			stateError: {
				show: false,
				msg: '',
				notSameEmailCodeValidation: true
			},

			showModalInfoCode: false,
			messageModalInfoCode: ''
		};
	},

	validations: {
		email: { required, email },
		emailCodeValidation: { required }
	},

	components: {
		CustomLabelMandatory
	},

	computed: {
		disableVerifyEmail() {
			const emailfilter = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
			if (!emailfilter.test(this.email)) {
				return true;
			}
			return false;
		},
		showVerificationMail() {
			if (this.dataToCheck.emailCodeValidation !== 0) {
				return true;
			}
			return false;
		}
	},

	methods: {
		getStateValidationField(field) {
			if (this.$v[field].$error === false) {
				return null;
			}

			return false;
		},
		async verifyEmail() {
			try {
				this.loadingFor.verifyEmail = true;
				const email = this.email;

				const result = await emailValidationCode({
					email: this.email,
					language: 0
				});
				this.dataToCheck.licenseId = result.id
				this.dataToCheck.emailCodeValidation = result.emailValidationCode;
				this.dataToCheck.email = email;

				this.showModalInfoCode = true;
				this.messageModalInfoCode = this.FormMSG(60, 'An e-mail containing a validation code has just been sent to') + ' ' + email;

				this.emailCodeValidation = null;
				this.emailValidationProcess = result.emailValidationProcess;

				this.loadingFor.verifyEmail = false;
			} catch (e) {
				this.stateError.msg = this.resolveGqlError(e);
				this.stateError.show = true;
				this.loadingFor.verifyEmail = false;
			}
		},
		async handleInputEmailCodeValidation() {
			this.emailVerified = await checkLicenseEmailValidationCode(this.dataToCheck.licenseId, this.emailCodeValidation);
			if (this.emailVerified) {
				this.stateError.notSameEmailCodeValidation = true;
				this.emailValidated = true;

				this.$emit('step-one-form:ok', {
					email: this.email
				});
			} else {
				this.stateError.notSameEmailCodeValidation = false;
				this.emailValidated = false;
			}
		},
		handleDismissedError() {
			this.stateError.show = false;
			this.stateError.msg = '';
		}
	}
};
</script>
