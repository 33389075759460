var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", { staticClass: "text-mobile-sub-title sub-title ml-0 mb-2" }, [
        _vm._v(_vm._s(_vm.FormMSG(266, "Contact information"))),
      ]),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(267, "Contact name") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.contactName"),
              placeholder: _vm.FormMSG(1678, "Enter name"),
            },
            model: {
              value: _vm.$v.dataToSend.contactName.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.contactName, "$model", $$v)
              },
              expression: "$v.dataToSend.contactName.$model",
            },
          }),
          _vm.$v.dataToSend.contactName.$error &&
          _vm.getStateValidationField("dataToSend.contactName") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1679, "Please enter name")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(268, "Contact email") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.contactEmail"),
              placeholder: _vm.FormMSG(1680, "Enter contact email"),
            },
            model: {
              value: _vm.$v.dataToSend.contactEmail.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.contactEmail, "$model", $$v)
              },
              expression: "$v.dataToSend.contactEmail.$model",
            },
          }),
          !_vm.$v.dataToSend.contactEmail.required &&
          _vm.getStateValidationField("dataToSend.contactEmail") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1681, "Please enter contact email")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
          !_vm.$v.dataToSend.contactEmail.email &&
          _vm.getStateValidationField("dataToSend.contactEmail") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1702, "Please enter an email valid")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(269, "Contact phone"),
                      "not-required": "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: { placeholder: _vm.FormMSG(1683, "Enter contact phone") },
            model: {
              value: _vm.dataToSend.contactPhone,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "contactPhone", $$v)
              },
              expression: "dataToSend.contactPhone",
            },
          }),
        ],
        1
      ),
      _c("h2", { staticClass: "text-mobile-sub-title sub-title ml-0 mb-2" }, [
        _vm._v(_vm._s(_vm.FormMSG(256, "Personal data"))),
      ]),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(257, "Country") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("v-select", {
            attrs: {
              options: _vm.countries,
              label: "text",
              clearable: false,
              reduce: (option) => option.value,
            },
            on: { input: _vm.getCountry },
            model: {
              value: _vm.dataToSend.personalDataCountry,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "personalDataCountry", $$v)
              },
              expression: "dataToSend.personalDataCountry",
            },
          }),
        ],
        1
      ),
      _c("VatChecker", {
        on: { change: _vm.handleVatChange },
        model: {
          value: _vm.dataToSend.personalDataTva,
          callback: function ($$v) {
            _vm.$set(_vm.dataToSend, "personalDataTva", $$v)
          },
          expression: "dataToSend.personalDataTva",
        },
      }),
      _c("google-auto-complete", {
        ref: "step-three-google-search",
        attrs: {
          label: _vm.FormMSG(259, "Internet search"),
          "put-search": _vm.searchAddress,
          country: _vm.internetSearch,
        },
        on: { placechanged: (item) => _vm.handleAutoComplete(item) },
      }),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(261, "Company name") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField(
                "dataToSend.personalDataCompanyName"
              ),
              placeholder: _vm.FormMSG(1658, "Enter company name"),
            },
            model: {
              value: _vm.$v.dataToSend.personalDataCompanyName.$model,
              callback: function ($$v) {
                _vm.$set(
                  _vm.$v.dataToSend.personalDataCompanyName,
                  "$model",
                  $$v
                )
              },
              expression: "$v.dataToSend.personalDataCompanyName.$model",
            },
          }),
          _vm.$v.dataToSend.personalDataCompanyName.$error &&
          _vm.getStateValidationField("dataToSend.personalDataCompanyName") !==
            null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1659, "Please enter company name")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(262, "Street") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField(
                "dataToSend.personalDataStreet"
              ),
              placeholder: _vm.FormMSG(1670, "Enter street"),
            },
            model: {
              value: _vm.$v.dataToSend.personalDataStreet.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.personalDataStreet, "$model", $$v)
              },
              expression: "$v.dataToSend.personalDataStreet.$model",
            },
          }),
          _vm.$v.dataToSend.personalDataStreet.$error &&
          _vm.getStateValidationField("dataToSend.personalDataStreet") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1671, "Please enter street")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(263, "Number") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField(
                "dataToSend.personalDataNumber"
              ),
              placeholder: _vm.FormMSG(1672, "Enter number"),
            },
            model: {
              value: _vm.$v.dataToSend.personalDataNumber.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.personalDataNumber, "$model", $$v)
              },
              expression: "$v.dataToSend.personalDataNumber.$model",
            },
          }),
          _vm.$v.dataToSend.personalDataNumber.$error &&
          _vm.getStateValidationField("dataToSend.personalDataNumber") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1673, "Please enter number")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(264, "Postal code") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.personalDataZip"),
              placeholder: _vm.FormMSG(1674, "Enter postal code"),
            },
            model: {
              value: _vm.$v.dataToSend.personalDataZip.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.personalDataZip, "$model", $$v)
              },
              expression: "$v.dataToSend.personalDataZip.$model",
            },
          }),
          _vm.$v.dataToSend.personalDataZip.$error &&
          _vm.getStateValidationField("dataToSend.personalDataZip") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1675, "Please enter postal code")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(265, "City") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.personalDataCity"),
              placeholder: _vm.FormMSG(1676, "Enter city"),
            },
            model: {
              value: _vm.$v.dataToSend.personalDataCity.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.personalDataCity, "$model", $$v)
              },
              expression: "$v.dataToSend.personalDataCity.$model",
            },
          }),
          _vm.$v.dataToSend.personalDataCity.$error &&
          _vm.getStateValidationField("dataToSend.personalDataCity") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(1677, "Please enter city")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }