<template>
	<div>
		<b-row v-if="stateError.show">
			<b-col>
				<b-alert show variant="danger" dismissible @dismissed="handleDismissed">
					{{ stateError.msg }}
				</b-alert>
			</b-col>
		</b-row>
		<h2 class="text-mobile-sub-title sub-title ml-0 mb-2">{{ FormMSG(256, 'Personal data') }}</h2>

		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(257, 'First name')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.firstName.$model"
				:placeholder="FormMSG(1987, 'Enter first name')"
				:state="getStateValidationField('dataToSend.firstName')"
			/>
			<div v-if="$v.dataToSend.firstName.$error && getStateValidationField('dataToSend.firstName') !== null" class="invalid-feedback">
				{{ FormMSG(1988, 'Please enter first name') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(258, 'Last name')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.name.$model"
				:placeholder="FormMSG(1010, 'Enter last name')"
				:state="getStateValidationField('dataToSend.name')"
			/>
			<div v-if="$v.dataToSend.name.$error && getStateValidationField('dataToSend.name') !== null" class="invalid-feedback">
				{{ FormMSG(3011, 'Please enter last name') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(259, 'Email address')" />
			</template>
			<b-form-input :value="dataToSend.email" disabled />
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(987, 'Phone')" />
			</template>
			<div class="d-flex">
				<div>
					<b-input-group-prepend style="height: 100%">
						<b-input-group-text style="border-radius: 0.25rem 0 0 0.25rem">
							<component :is="getLucideIcon('Phone')" color="#4d4e4f" :size="16" />
						</b-input-group-text>
					</b-input-group-prepend>
				</div>
				<div class="w-100">
					<div v-if="phoneInited" class="w-100">
						<VuePhoneNumberInput
							v-model="$v.phone.$model"
							error-color="#EA4E2C"
							valid-color="#00AF75"
							size="sm"
							:translations="optionsTranslation"
							:disabled="phoneValidated"
							fetch-country
							@update="handleUpdatePhoneNumber"
						/>
					</div>
					<div v-else>
						<VuePhoneNumberInput
							v-model="$v.phone.$model"
							error-color="#EA4E2C"
							valid-color="#00AF75"
							size="sm"
							:translations="optionsTranslation"
							:disabled="phoneValidated"
							:default-country-code="'BE'"
							@update="handleUpdatePhoneNumber"
						/>
					</div>
				</div>
			</div>
			<div v-if="$v.phone.$error && getStateValidationField('phone') !== null" class="invalid-feedback">
				{{ FormMSG(892, 'Phone is required') }}
			</div>
		</b-form-group>
		<b-row v-if="!phoneValidated" class="mb-3">
			<b-col :cols="!showVerificationPhone ? 12 : 4" :class="{ 'd-flex align-items-end pb-3': showVerificationPhone }">
				<b-button variant="primary" block :disabled="disableVerifyPhone || loadingFor.verifyPhone" @click="verifyPhone">
					<b-spinner v-if="loadingFor.verifyPhone" small />
					<span>
						{{ labelPhoneAction }}
					</span>
				</b-button>
			</b-col>
			<b-col v-if="showVerificationPhone" :cols="8">
				<b-form-group>
					<template #label>
						<custom-label-mandatory :label="FormMSG(597, 'Phone verification code')" />
					</template>
					<b-form-input
						v-model="phoneCodeValidation"
						placeholder="ex: 1234"
						:state="stateError.phoneCodeValidation"
						:disabled="validateCodePhone"
						@input="handleInputPhoneCodeValidation"
					/>
				</b-form-group>
			</b-col>
		</b-row>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(261, 'Nationality')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.nationality.$model"
				:placeholder="FormMSG(1012, 'Enter nationality')"
				:state="getStateValidationField('dataToSend.nationality')"
			/>
			<div v-if="$v.dataToSend.nationality.$error && getStateValidationField('dataToSend.nationality') !== null" class="invalid-feedback">
				{{ FormMSG(2013, 'Please enter nationality') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(262, 'Language')" />
			</template>
			<b-form-select v-model="dataToSend.language" :options="optionsForLanguages" />
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(263, 'Gender')" />
			</template>
			<b-form-select v-model="$v.dataToSend.gender.$model" :options="menuGenders" :state="getStateValidationField('dataToSend.gender')" />
			<div v-if="$v.dataToSend.gender.$error && getStateValidationField('dataToSend.gender') !== null" class="invalid-feedback">
				{{ FormMSG(1674, 'Please select a gender') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(265, 'Birthdate')" />
			</template>
			<v-date-picker :value="$v.dataToSend.birthDate.$model" :masks="{ input: 'DD/MM/YYYY' }" @input="handleInputBirthDate">
				<template v-slot="{ inputValue, togglePopover }">
					<b-input-group>
						<b-form-input :value="inputValue" readonly class="bg-white" @click="togglePopover" />
						<b-input-group-append>
							<b-input-group-text class="cursor-pointer" @click="togglePopover">
								<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</template>
			</v-date-picker>
			<div v-if="$v.dataToSend.birthDate.$error && getStateValidationField('dataToSend.birthDate')" class="invalid-feedback">
				{{ FormMSG(1563, 'Please enter birthdate') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(266, 'Place of birth(City and Country)')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.placeOfBirth.$model"
				:state="getStateValidationField('dataToSend.placeOfBirth')"
				:placeholder="FormMSG(1698, 'Enter place of birth')"
			/>
			<div v-if="$v.dataToSend.placeOfBirth.$error && getStateValidationField('dataToSend.placeOfBirth') !== null" class="invalid-feedback">
				{{ FormMSG(2699, 'Please enter place of birth') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(1266, 'Country of birth')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.birthCountry.$model"
				:state="getStateValidationField('dataToSend.placeOfBirth')"
				:placeholder="FormMSG(1698, 'Enter place of birth')"
			/>
			<div v-if="$v.dataToSend.birthCountry.$error && getStateValidationField('dataToSend.birthCountry') !== null" class="invalid-feedback">
				{{ FormMSG(2851, 'Please enter country of birth') }}
			</div>
		</b-form-group>

		<h2 class="text-mobile-sub-title sub-title ml-0 mb-2">{{ FormMSG(270, 'Identification') }}</h2>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(271, 'National registration number')" />
			</template>
			<InputMask
				id="nationalRegistryNumberJobRequest"
				class="form-control"
				v-model="$v.dataToSend.nationalRegistryNumber.$model"
				mask="99.99.99-999-99"
				:placeholder="FormMSG(1700, 'Enter national registration number')"
			/>
			<div v-if="$v.dataToSend.nationalRegistryNumber.$error && getStateValidationField('dataToSend.nationalRegistryNumber')" class="invalid-feedback">
				{{ FormMSG(1701, 'Please enter national registration number') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(2720, 'Company number')" not-required />
			</template>
			<b-form-input v-model="dataToSend.companyNumber" :placeholder="FormMSG(1703, 'Enter company number')" />
		</b-form-group>

		<h2 class="text-mobile-sub-title sub-title ml-0 mb-2">{{ FormMSG(2730, 'Financial information') }}</h2>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(275, 'Tax withholding')" />
			</template>
			<b-input-group>
				<b-form-input
					v-model="$v.dataToSend.taxesPercentage.$model"
					:state="getStateValidationField('dataToSend.taxesPercentage')"
					:placeholder="FormMSG(1705, 'Enter tax with holding')"
				/>
				<b-input-group-append>
					<b-input-group-text class="cursor-pointer">
						<component :is="getLucideIcon('Percent')" color="#06263E" :size="16" />
					</b-input-group-text>
				</b-input-group-append>
			</b-input-group>
			<div v-if="$v.dataToSend.taxesPercentage.$error && getStateValidationField('dataToSend.taxesPercentage')" class="invalid-feedback">
				{{ FormMSG(1706, 'Please enter tax with holding') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(279, 'Iban')" />
			</template>
			<b-form-input v-model="$v.dataToSend.iban.$model" :state="getStateValidationField('dataToSend.iban')" :placeholder="FormMSG(1707, 'Enter iban')" />
			<div v-if="$v.dataToSend.iban.$error && getStateValidationField('dataToSend.iban')" class="invalid-feedback">
				{{ FormMSG(1708, 'Please enter iban') }}
			</div>
		</b-form-group>
		<b-form-group :label="FormMSG(280, 'Bic (if not in Belgium)')">
			<b-form-input v-model="dataToSend.bic" :placeholder="FormMSG(1709, 'Enter Bic')" />
		</b-form-group>
		<b-row>
			<b-col
				sm="12"
				:md="imageInDb && imageInDb !== '' ? 10 : 12"
				:lg="imageInDb && imageInDb !== '' ? 10 : 12"
				:xl="imageInDb && imageInDb !== '' ? 10 : 12"
			>
				<b-form-group :label="FormMSG(281, 'Bank detail document (in not in Belgium)')">
					<b-form-file
						ref="accomodationFile"
						class="mb-2"
						:accept="supportedMimeTypes"
						:placeholder="FormMSG(63, 'Choose a file or drop it here ...')"
						:browse-text="FormMSG(64, 'Browse')"
						:drop-placeholder="FormMSG(65, 'Drop file here ...')"
						@change="handleChangeFileToUploaded"
					/>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="2" lg="2" xl="2" v-if="imageInDb && imageInDb !== ''">
				<div class="w-100 text-center" :style="$screen.width <= 576 ? '' : 'padding-top: 22px'">
					<b-button-group tag="div">
						<b-button variant="outline-primary" @click="handleClickViewImage">
							<component :is="getLucideIcon('Eye')" :size="16" />
						</b-button>
						<b-button variant="danger" @click="handleClickRemoveImage">
							<component :is="getLucideIcon('Trash2')" :size="16" />
						</b-button>
					</b-button-group>
				</div>
			</b-col>
		</b-row>

		<h2 class="text-mobile-sub-title sub-title ml-0 mb-2">{{ FormMSG(1517, 'Address') }}</h2>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(285, 'Country')" />
			</template>
			<v-select
				v-model="dataToSend.country"
				:options="countries"
				label="text"
				:clearable="false"
				:reduce="(option) => option.value"
				@input="getCountry"
			/>
		</b-form-group>
		<google-auto-complete
			ref="google-search-address"
			:label="FormMSG(286, 'Internet search')"
			:country="dataToSend.internalSearch"
			@placechanged="(item) => handleAutoComplete(item)"
		/>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(287, 'Street')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.street.$model"
				:state="getStateValidationField('dataToSend.street')"
				:placeholder="FormMSG(1589, 'Enter street')"
			/>
			<div v-if="$v.dataToSend.street.$error && getStateValidationField('dataToSend.street') !== null" class="invalid-feedback">
				{{ FormMSG(1547, 'Please enter street') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(288, 'Number')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.number.$model"
				:state="getStateValidationField('dataToSend.number')"
				:placeholder="FormMSG(4781, 'Enter number')"
			/>
			<div v-if="$v.dataToSend.number.$error && getStateValidationField('dataToSend.number') !== null" class="invalid-feedback">
				{{ FormMSG(4782, 'Please enter number') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(289, 'Postal code')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.zip.$model"
				:state="getStateValidationField('dataToSend.zip')"
				:placeholder="FormMSG(1478, 'Enter postal code')"
			/>
			<div v-if="$v.dataToSend.zip.$error && getStateValidationField('dataToSend.zip') !== null" class="invalid-feedback">
				{{ FormMSG(1456, 'Please enter postal code') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(291, 'City')" />
			</template>
			<b-form-input v-model="$v.dataToSend.city.$model" :state="getStateValidationField('dataToSend.city')" :placeholder="FormMSG(2547, 'Enter city')" />
			<div v-if="$v.dataToSend.city.$error && getStateValidationField('dataToSend.city') !== null" class="invalid-feedback">
				{{ FormMSG(2548, 'Please enter city') }}
			</div>
		</b-form-group>
		<b-form-group>
			<label for="state">{{ FormMSG(2002, 'Social Security Number') }} </label>
			<b-form-input
				v-model="dataToSend.socSecNumber"
				:placeholder="FormMSG(2003, 'Enter your Social Security Number')"
				@input="handleSocSecNumberChange"
				@keydown.enter.prevent
				maxlength="15"
			/>
			<div v-if="isSocSecNumberWarning" class="warning-feedback">
				{{ FormMSG(8979997, 'The social security number seems not valid') }}
			</div>
		</b-form-group>
		<h2 class="text-mobile-sub-title sub-title ml-0 mb-2">{{ FormMSG(293, 'Family') }}</h2>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(294, 'Civil status')" />
			</template>
			<b-form-select v-model="dataToSend.civilStatus" :options="optionsForCivilStatus" @change="handleChangeCivilStatus" />
		</b-form-group>
		<b-form-group v-if="showSpouseInfo">
			<template #label>
				<custom-label-mandatory :label="FormMSG(295, 'Spouse first name')" not-required />
			</template>
			<b-form-input v-model="dataToSend.spouseFirstName" :placeholder="FormMSG(1584, 'Enter spouse first name')" />
		</b-form-group>
		<b-form-group v-if="showSpouseInfo">
			<template #label>
				<custom-label-mandatory :label="FormMSG(296, 'Spouse last name')" not-required />
			</template>
			<b-form-input v-model="dataToSend.spouseName" :placeholder="FormMSG(1586, 'Enter spouse last name')" />
		</b-form-group>
		<b-form-group v-if="showSpouseInfo">
			<template #label>
				<custom-label-mandatory :label="FormMSG(297, 'Wedding date')" not-required />
			</template>
			<v-date-picker :value="dataToSend.mariedOn" :masks="{ input: 'DD/MM/YYYY' }" @input="handleInputWeddingDate">
				<template v-slot="{ inputValue, togglePopover }">
					<b-input-group>
						<b-form-input :value="inputValue" readonly class="bg-white" @click="togglePopover" />
						<b-input-group-append>
							<b-input-group-text class="cursor-pointer" @click="togglePopover">
								<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</template>
			</v-date-picker>
		</b-form-group>
		<b-form-group v-if="showSpouseInfo" :label="FormMSG(298, 'Spouse profession')">
			<b-form-input v-model="dataToSend.spouseProfession" :placeholder="FormMSG(847, 'Enter spouse profession')" />
		</b-form-group>

		<b-form-group v-if="showSpouseInfo">
			<template #label>
				<custom-label-mandatory :label="FormMSG(300, 'Spouse birth date')" not-required />
			</template>
			<v-date-picker :value="dataToSend.spouseBirthDate" :masks="{ input: 'DD/MM/YYYY' }" @input="handleInputSpouseBirthDate">
				<template v-slot="{ inputValue, togglePopover }">
					<b-input-group>
						<b-form-input :value="inputValue" readonly class="bg-white" @click="togglePopover" />
						<b-input-group-append>
							<b-input-group-text class="cursor-pointer" @click="togglePopover">
								<component :is="getLucideIcon('Calendar')" color="#06263E" :size="16" />
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</template>
			</v-date-picker>
		</b-form-group>
		<b-form-group v-if="showSpouseInfo" :label="FormMSG(299, 'Spouse has salary')">
			<b-form-checkbox v-model="dataToSend.spouseHasSalary" switch size="lg" />
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(301, 'Number of dependent child')" not-required />
			</template>
			<b-form-input v-model="dataToSend.numberOfDependentChild" type="number" step="1" min="0" placeholder="0" />
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(1302, 'Number of disabled child')" not-required />
			</template>
			<b-form-input v-model="dataToSend.numberOfDisabledChild" type="number" step="1" min="0" placeholder="0" />
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(303, 'Number of dependent person')" not-required />
			</template>
			<b-form-input v-model="dataToSend.numberOfDependentPerson" type="number" step="1" min="0" placeholder="0" />
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(304, 'Number of disabled person ')" not-required />
			</template>
			<b-form-input v-model="dataToSend.numberOfDisabledPerson" type="number" step="1" min="0" placeholder="0" />
		</b-form-group>

		<h2 class="text-mobile-sub-title sub-title ml-0 mb-2">{{ FormMSG(305, 'Emergency contact') }}</h2>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(306, 'Person to contact name')" not-required />
			</template>
			<b-form-input v-model="dataToSend.personToContact" :placeholder="FormMSG(1689, 'Enter person to contact name')" />
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(307, 'Person to contact phone')" not-required />
			</template>
			<b-form-input v-model="dataToSend.personToContactPhone" :placeholder="FormMSG(1690, 'Enter person to contact phone')" />
		</b-form-group>

		<h2 class="text-mobile-sub-title sub-title ml-0 mb-2">{{ FormMSG(308, 'Study') }}</h2>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(1307, 'Are you currently a student ?')" not-required />
			</template>
			<b-form-checkbox v-model="dataToSend.studyCurrently" switch size="lg" @change="handleChangeStudyCurrently" />
		</b-form-group>

		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(311, 'Study level')" not-required />
			</template>
			<b-form-select v-model="dataToSend.studyLevel" :options="studyLevelOptions" />
		</b-form-group>

		<b-modal
			header-class="header-class-modal-doc-package"
			:title="FormMSG(1282, 'Validation code')"
			class="modal-success"
			v-model="showModalInfoCode"
			@ok="showModalInfoCode = false"
			ok-variant="success"
			ok-only
		>
			{{ messageModalInfoCode }}
		</b-modal>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import CustomLabelMandatory from './CustomLabelMandatory.vue';
import { Phone } from 'lucide-vue';
import _ from 'lodash';
import { NANP_COUNTRY_CODES } from '@/shared/constants';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { phoneValidationCode, checkLicensePhoneValidationCode } from '@/cruds/registration.crud';
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import GoogleAutoComplete from '@/components/GoogleAutoComplete';
import { isNil, getObjectFromValue, emailValid, noTilde, capitalize } from '~utils';
import { STUDY_LEVEL_MENU } from '../../../shared/constants';
import InputMask from 'primevue/inputmask';
import { isFileSupported } from '@/utils/isFileSupported';

export default {
	name: 'StepTwoForm',

	mixins: [languageMessages, globalMixin, validationMixin],

	props: {
		userData: { type: Object, default: () => {}, required: false }
	},

	components: {
		Phone,
		CustomLabelMandatory,
		VuePhoneNumberInput,
		'v-date-picker': DatePicker,
		GoogleAutoComplete,
		InputMask
	},

	data() {
		return {
			imageInDb: '',
			phone: null,
			phoneValidated: false,
			validateCodePhone: false,
			phoneE164: '',
			disableVerifyPhone: true,
			isNANPCountry: false,
			phoneCodeValidation: null,
			phoneInited: false,
			dataToCheck: {
				phone: '',
				phoneCodeValidation: 0,
				licenseId: ''
			},

			loadingFor: {
				verifyPhone: false
			},

			stateError: {
				show: false,
				msg: '',
				phoneCodeValidation: null
			},

			showModalInfoCode: false,
			messageModalInfoCode: '',
			isSocSecNumberWarning: false,
			dataToSend: {
				phone: '',
				firstName: null,
				name: null,
				email: '',
				nationality: null,
				language: 0,
				gender: null,
				birthDate: new Date(),
				placeOfBirth: null,
				birthCountry: null,

				nationalRegistryNumber: null,
				companyNumber: null,

				taxesPercentage: null,
				iban: null,
				bic: '',

				// ADDRESS
				country: 16965,
				internalSearch: null,
				street: null,
				number: null,
				zip: null,
				city: null,

				civilStatus: 0,
				spouseFirstName: null,
				spouseName: null,
				mariedOn: new Date(),
				spouseProfession: '',
				spouseHasSalary: false,
				numberOfDependentChild: 0,
				numberOfDisabledChild: 0,
				numberOfDependentPerson: 0,
				numberOfDisabledPerson: 0,

				personToContact: null,
				personToContactPhone: null,

				studyCurrently: false,
				studyLevel: 0,
				bankDetailXid: null,
				spouseBirthDate: new Date(),
				socSecNumber: ''
			}
		};
	},

	watch: {
		userData: {
			handler(newVal) {
				if (newVal.phone !== '') {
					this.phone = newVal.phone;
					this.phoneE164 = newVal.phone;

					this.phoneInited = true;
				}

				if (newVal.bankDetailXid && newVal.bankDetailXid !== '') {
					this.imageInDb = newVal.bankDetailXid;
				}

				this.dataToSend = _.cloneDeep(newVal);
				this.dataToSend.bankDetailXid = null;
			},
			immediate: true,
			deep: true
		},
		dataToSend: {
			handler(newVal) {
				this.$emit('step-two-form:change', { data: newVal });
			},
			deep: true,
			immediate: true
		}
	},

	computed: {
		supportedMimeTypes() {
			return '.pdf,.xlsx,.xls,.doc,.docx';
		},
		studyLevelOptions() {
			return [{ value: 0, text: this.FormMSG(1547, 'Please select ...') }, ...this.FormMenu(STUDY_LEVEL_MENU)];
		},
		showSpouseInfo() {
			return [3, 6, 7].includes(this.dataToSend.civilStatus);
		},
		optionsForCivilStatus() {
			return this.FormMenu(1001);
		},
		countries() {
			let countries = this.FormMenu(8);

			countries.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return countries;
		},
		menuGenders() {
			const res = this.FormMenu(1002);
			return [{ value: null, text: this.FormMSG(165, 'Please select an option') }, ...res];
		},
		optionsForLanguages() {
			return this.FormMenu(1115);
		},
		showVerificationPhone() {
			if (this.dataToCheck.phoneCodeValidation !== 0) {
				return true;
			}
			return false;
		},
		optionsTranslation() {
			return {
				countrySelectorLabel: this.FormMSG(54, 'Country code'),
				countrySelectorError: this.FormMSG(55, 'Choose a country'),
				phoneNumberLabel: this.FormMSG(56, 'Phone number'),
				example: 'Ex : '
			};
		},
		labelPhoneAction() {
			if (this.isNANPCountry) {
				return this.FormMSG(159, 'Validated');
			}
			if (this.dataToCheck.phoneCodeValidation !== 0 && !this.loadingFor.verifyPhone) {
				return this.FormMSG(48, 'Resend code');
			}
			if (!this.loadingFor.verifyPhone && this.dataToCheck.phoneCodeValidation === 0) {
				return this.FormMSG(49, 'Verify phone');
			}
			if (this.loadingFor.verifyPhone) {
				return this.FormMSG(50, 'Check phone');
			}
		}
	},

	methods: {
		handleInputWeddingDate(payload) {
			this.dataToSend.mariedOn = payload;
		},
		handleSocSecNumberChange(value) {
			this.dataToSend.socSecNumber = value;
			this.isSocSecNumberWarning = _.isNil(value);
		},
		handleInputSpouseBirthDate(payload) {
			this.dataToSend.spouseBirthDate = payload;
		},
		handleChangeCivilStatus(payload) {
			if (![3, 6, 7].includes(payload)) {
				this.dataToSend.spouseFirstName = null;
				this.dataToSend.spouseName = null;
				this.dataToSend.mariedOn = null;
				this.dataToSend.spouseProfession = '';
				this.dataToSend.spouseHasSalary = false;
			} else {
				this.dataToSend.mariedOn =
					this.dataToSend.mariedOn === null || this.dataToSend.mariedOn === '0001-01-01T00:00:00Z' ? new Date() : this.dataToSend.mariedOn;
			}
		},
		handleClickViewImage() {
			const url = `${process.env.VUE_APP_GQL}/images/${this.imageInDb}`;
			window.open(url, '_blank');
		},
		async handleClickRemoveImage(showConfirm = true) {
			if (showConfirm) {
				const action = async () => {
					alert(this.imageInDb);
					await this.$axios.$get(`/removeimage/${this.imageInDb}`);
					this.imageInDb = '';
				};
				this.confirmModal(action, this.FormMSG(457, 'Are you sure ?'));
			} else {
				await this.$axios.$get(`/removeimage/${this.imageInDb}`);
				this.imageInDb = '';
			}
		},
		async handleChangeFileToUploaded(e) {
			let eventFile = null;
			if (e.type === 'drop') {
				eventFile = e.dataTransfer.files[0];
			} else {
				eventFile = e.target.files[0];
			}
			if (isFileSupported(eventFile, [supportedMimeTypes])) {
				this.dataToSend.bankDetailXid = eventFile;

				if (this.imageInDb !== '') {
					await this.handleClickRemoveImage(false);
				}

				const fileReader = new FileReader();
				fileReader.readAsDataURL(this.eventFile);
			} else {
				this.createToastForMobile(
					this.FormMSG(123, 'Error'),
					eventFile.name + ' : ' + this.FormMSG(124, 'The file format is not supported. Please upload a supported file.'),
					'',
					'danger'
				);
				this.$refs.accomodationFile.reset();
			}
		},
		isInvalid() {
			this.$v.$touch();

			return this.$v.$invalid;
		},
		handleChangeStudyCurrently(value) {
			// if (!value) {
			// 	this.dataToSend.studyLevel = 0;
			// }
		},
		getCountry() {
			this.$refs['google-search-address'].clearSelected();
		},
		handleAutoComplete(addressData) {
			this.dataToSend.street = addressData.route || addressData.place.name;
			this.dataToSend.city = addressData.locality;
			this.dataToSend.number = addressData.street_number || '';
			const findCountry = this.countries.find((option) => option.text === addressData.country);
			this.dataToSend.country = findCountry.value;
			// this.dataToSend.country = addressData.country;
			this.dataToSend.zip = addressData.postal_code;
		},
		handleInputBirthDate(payload) {
			this.dataToSend.birthDate = payload;
		},
		async handleInputPhoneCodeValidation() {
			let verif = await this.verifyMatchingCode();
			if (!verif) {
				this.stateError.phoneCodeValidation = false;
				this.codeVerified = false;
			} else {
				this.stateError.phoneCodeValidation = true;
				this.codeVerified = true;
			}
		},
		async verifyMatchingCode() {
			if (this.dataToCheck.licenseId) {
				try {
					const isValidFromServer = await checkLicensePhoneValidationCode(this.dataToCheck.licenseId, this.phoneCodeValidation);
					this.validateCodePhone = isValidFromServer.CheckLicensePhoneValidationCode;
					if (this.validateCodePhone) {
						this.dataToSend.phone = this.phone;
					}
					return isValidFromServer.CheckLicensePhoneValidationCode;
				} catch (error) {
					return false;
				}
			}
		},
		async verifyPhone() {
			try {
				if (!this.isNANPCountry) {
					this.loadingFor.verifyPhone = true;
					const phone = this.phoneE164;
					const result = await phoneValidationCode({
						email: this.email,
						phone: this.phoneE164,
						language: 0
					});
					this.dataToCheck.licenseId = result.id;
					this.dataToCheck.phoneCodeValidation = 1;
					this.dataToCheck.phone = phone;

					this.showModalInfoCode = true;
					this.messageModalInfoCode = this.FormMSG(61, 'An SMS containing a validation code has just been sent to') + ' ' + phone;

					this.phoneCodeValidation = '';
					this.stateError.phoneCodeValidation = null;
					this.loadingFor.verifyPhone = false;
				} else {
					this.stateError.phoneCodeValidation = true;
					this.phoneValidated = true;
				}
			} catch (e) {
				this.stateError.msg = this.resolveGqlError(e);
				this.stateError.show = true;
				this.loadingFor.verifyPhone = false;
			}
		},
		handleDismissed() {
			this.stateError.show = false;
			this.stateError.msg = '';
		},
		handleUpdatePhoneNumber(payload) {
			if (payload.e164) {
				this.phoneE164 = payload.e164;
			} else {
				this.phoneE164 = '';
			}
			if (_.has(payload, 'isValid')) {
				this.disableVerifyPhone = !payload.isValid;
			}
			if (NANP_COUNTRY_CODES.includes(payload.countryCode)) {
				this.isNANPCountry = true;
			}
		},
		getStateValidationField(field) {
			const customCond = `this.$v.${field}.$error === false`;
			if (eval(customCond)) {
				return null;
			}

			return false;
		}
	},

	validations() {
		let result = {
			phone: { required },
			dataToSend: {
				firstName: { required },
				name: { required },
				nationality: { required },
				birthDate: { required },
				placeOfBirth: { required },
				birthCountry: { required },
				gender: { required },

				nationalRegistryNumber: { required },

				taxesPercentage: { required },
				iban: { required },

				country: { required },
				street: { required },
				number: { required },
				zip: { required },
				city: { required }
			}
		};

		return result;
	}
};
</script>
