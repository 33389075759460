var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", { staticClass: "text-mobile-sub-title sub-title ml-0 mb-2" }, [
        _vm._v(_vm._s(_vm.FormMSG(256, "Project information"))),
      ]),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(257, "Project name") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.projectName"),
              placeholder: _vm.FormMSG(2101, "Enter project name"),
            },
            model: {
              value: _vm.$v.dataToSend.projectName.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.projectName, "$model", $$v)
              },
              expression: "$v.dataToSend.projectName.$model",
            },
          }),
          _vm.$v.dataToSend.projectName.$error &&
          _vm.getStateValidationField("dataToSend.projectName") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(2102, "Please enter project name")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(258, "Project type") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-select", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.productionType"),
              options: _vm.mapTgsproductionType,
            },
            model: {
              value: _vm.$v.dataToSend.productionType.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.productionType, "$model", $$v)
              },
              expression: "$v.dataToSend.productionType.$model",
            },
          }),
          _vm.$v.dataToSend.productionType.$error &&
          _vm.getStateValidationField("dataToSend.productionType") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(2559, "Please select an project type")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c("h2", { staticClass: "text-mobile-sub-title sub-title ml-0 mb-2" }, [
        _vm._v(_vm._s(_vm.FormMSG(259, "Contract information"))),
      ]),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(260, "Department") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField(
                "dataToSend.contractDepartment"
              ),
              placeholder: _vm.FormMSG(2103, "Enter department"),
            },
            model: {
              value: _vm.$v.dataToSend.contractDepartment.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.contractDepartment, "$model", $$v)
              },
              expression: "$v.dataToSend.contractDepartment.$model",
            },
          }),
          _vm.$v.dataToSend.contractDepartment.$error &&
          _vm.getStateValidationField("dataToSend.contractDepartment") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(2102, "Please enter department")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(261, "Function") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-input", {
            attrs: {
              state: _vm.getStateValidationField("dataToSend.contractFunction"),
              placeholder: _vm.FormMSG(2104, "Enter function"),
            },
            model: {
              value: _vm.$v.dataToSend.contractFunction.$model,
              callback: function ($$v) {
                _vm.$set(_vm.$v.dataToSend.contractFunction, "$model", $$v)
              },
              expression: "$v.dataToSend.contractFunction.$model",
            },
          }),
          _vm.$v.dataToSend.contractFunction.$error &&
          _vm.getStateValidationField("dataToSend.contractFunction") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(2105, "Please enter function")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(262, "Start date") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("v-date-picker", {
            attrs: {
              value: _vm.$v.dataToSend.contractStartDate.$model,
              masks: { input: "DD/MM/YYYY" },
            },
            on: { input: _vm.handleInputStartDate },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ inputValue, togglePopover }) {
                  return [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          staticClass: "bg-white",
                          attrs: { value: inputValue, readonly: "" },
                          on: { click: togglePopover },
                        }),
                        _c(
                          "b-input-group-append",
                          [
                            _c(
                              "b-input-group-text",
                              {
                                staticClass: "cursor-pointer",
                                on: { click: togglePopover },
                              },
                              [
                                _c(_vm.getLucideIcon("Calendar"), {
                                  tag: "component",
                                  attrs: { color: "#06263E", size: 16 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.$v.dataToSend.contractStartDate.$error &&
          _vm.getStateValidationField("dataToSend.contractStartDate") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(2110, "Please enter start date")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: { label: _vm.FormMSG(269, "End date") },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("v-date-picker", {
            attrs: {
              value: _vm.$v.dataToSend.contractEndDate.$model,
              masks: { input: "DD/MM/YYYY" },
              "min-date": _vm.dataToSend.contractStartDate,
            },
            on: { input: _vm.handleInputEndDate },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ inputValue, togglePopover }) {
                  return [
                    _c(
                      "b-input-group",
                      [
                        _c("b-form-input", {
                          staticClass: "bg-white",
                          attrs: { value: inputValue, readonly: "" },
                          on: { click: togglePopover },
                        }),
                        _c(
                          "b-input-group-append",
                          [
                            _c(
                              "b-input-group-text",
                              {
                                staticClass: "cursor-pointer",
                                on: { click: togglePopover },
                              },
                              [
                                _c(_vm.getLucideIcon("Calendar"), {
                                  tag: "component",
                                  attrs: { color: "#06263E", size: 16 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm.$v.dataToSend.contractEndDate.$error &&
          _vm.getStateValidationField("dataToSend.contractStartDate") !== null
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(_vm.FormMSG(2111, "Please enter end date")) +
                    "\n\t\t"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-form-group",
        { attrs: { label: _vm.FormMSG(681, "Specify working days") } },
        [
          _c("b-form-checkbox", {
            attrs: {
              size: "lg",
              switch: "",
              value: true,
              "unchecked-value": false,
            },
            on: { change: _vm.handleChangeSpecifyWorkingDays },
            model: {
              value: _vm.dataToSend.specifyWorkingDays,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "specifyWorkingDays", $$v)
              },
              expression: "dataToSend.specifyWorkingDays",
            },
          }),
        ],
        1
      ),
      _c(
        "b-collapse",
        { attrs: { visible: _vm.dataToSend.specifyWorkingDays } },
        [
          _vm.dataToSend.specifyWorkingDays
            ? _c(
                "b-row",
                { staticClass: "mb-2" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("Calendar", {
                        attrs: {
                          "min-date": _vm.minDateValue,
                          "max-date": _vm.maxDateValue,
                          attributes: _vm.attributes,
                          locale: _vm.lang,
                          "is-expanded": "",
                        },
                        on: { dayclick: _vm.handleDayClick },
                      }),
                      _vm.invalidDateSelected === true
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(
                                    278,
                                    "Please select one or more dates"
                                  )
                                ) +
                                "\n\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      !_vm.dataToSend.specifyWorkingDays
        ? _c(
            "b-form-group",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "label",
                    fn: function () {
                      return [
                        _c("custom-label-mandatory", {
                          attrs: { label: _vm.FormMSG(270, "Days foreseen") },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                229147773
              ),
            },
            [
              _c("b-form-input", {
                attrs: {
                  state: _vm.getStateValidationField(
                    "dataToSend.contractDaysForeseen"
                  ),
                  placeholder: _vm.FormMSG(2115, "Enter days foreseen"),
                },
                model: {
                  value: _vm.$v.dataToSend.contractDaysForeseen.$model,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.$v.dataToSend.contractDaysForeseen,
                      "$model",
                      $$v
                    )
                  },
                  expression: "$v.dataToSend.contractDaysForeseen.$model",
                },
              }),
              !_vm.$v.dataToSend.contractDaysForeseen.required &&
              _vm.getStateValidationField("dataToSend.contractDaysForeseen") !==
                null
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(2116, "Please enter days foreseen")
                        ) +
                        "\n\t\t"
                    ),
                  ])
                : _vm._e(),
              !_vm.$v.dataToSend.contractDaysForeseen.minValue &&
              _vm.getStateValidationField("dataToSend.contractDaysForeseen") !==
                null
                ? _c("div", { staticClass: "invalid-feedback" }, [
                    _vm._v(
                      "\n\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            2117,
                            "The contract days foreseen must greater than 0"
                          )
                        ) +
                        "\n\t\t"
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
            [
              _c(
                "b-form-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("custom-label-mandatory", {
                            attrs: {
                              label: _vm.FormMSG(271, "Daily rate"),
                              "not-required": "",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        attrs: {
                          placeholder: _vm.FormMSG(2220, "Enter daily rate"),
                        },
                        model: {
                          value: _vm.dataToSend.contractDailyRate,
                          callback: function ($$v) {
                            _vm.$set(_vm.dataToSend, "contractDailyRate", $$v)
                          },
                          expression: "dataToSend.contractDailyRate",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            { staticClass: "cursor-pointer" },
                            [
                              _c(_vm.getLucideIcon("Euro"), {
                                tag: "component",
                                attrs: { size: 16 },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "6", lg: "6", xl: "6" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(364, "Salary type") } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.salaryTypeOptions },
                    model: {
                      value: _vm.dataToSend.salaryType,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataToSend, "salaryType", $$v)
                      },
                      expression: "dataToSend.salaryType",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(272, "Daily hours"),
                      "not-required": "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("input-date-time-picker", {
            attrs: {
              label: _vm.FormMSG(272, "Daily hours"),
              uiid: "minDay",
              type: "text",
              placeholder: "hh:mm",
              item: _vm.dataToSend.contractDailyHours,
              nbHour: _vm.rangeHour[_vm.WORKTYPE.daily],
              nbMinute: _vm.rangeMinute[_vm.WORKTYPE.daily],
              isEditable: () => true,
              fieldName: "totMinPerDay",
              workType: _vm.WORKTYPE.daily,
            },
            on: { change: _vm.handleChangeHourMin },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          scopedSlots: _vm._u([
            {
              key: "label",
              fn: function () {
                return [
                  _c("custom-label-mandatory", {
                    attrs: {
                      label: _vm.FormMSG(
                        297,
                        "Additional note (concerning deal memo)"
                      ),
                      "not-required": "",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("b-form-textarea", {
            model: {
              value: _vm.dataToSend.contractNote,
              callback: function ($$v) {
                _vm.$set(_vm.dataToSend, "contractNote", $$v)
              },
              expression: "dataToSend.contractNote",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }