<template>
	<div>
		<h2 class="text-mobile-sub-title sub-title ml-0 mb-2">{{ FormMSG(266, 'Contact information') }}</h2>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(267, 'Contact name')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.contactName.$model"
				:state="getStateValidationField('dataToSend.contactName')"
				:placeholder="FormMSG(1678, 'Enter name')"
			/>
			<div v-if="$v.dataToSend.contactName.$error && getStateValidationField('dataToSend.contactName') !== null" class="invalid-feedback">
				{{ FormMSG(1679, 'Please enter name') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(268, 'Contact email')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.contactEmail.$model"
				:state="getStateValidationField('dataToSend.contactEmail')"
				:placeholder="FormMSG(1680, 'Enter contact email')"
			/>
			<div v-if="!$v.dataToSend.contactEmail.required && getStateValidationField('dataToSend.contactEmail') !== null" class="invalid-feedback">
				{{ FormMSG(1681, 'Please enter contact email') }}
			</div>
			<div v-if="!$v.dataToSend.contactEmail.email && getStateValidationField('dataToSend.contactEmail') !== null" class="invalid-feedback">
				{{ FormMSG(1702, 'Please enter an email valid') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(269, 'Contact phone')" not-required />
			</template>
			<b-form-input v-model="dataToSend.contactPhone" :placeholder="FormMSG(1683, 'Enter contact phone')" />
		</b-form-group>

		<h2 class="text-mobile-sub-title sub-title ml-0 mb-2">{{ FormMSG(256, 'Personal data') }}</h2>

		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(257, 'Country')" />
			</template>
			<v-select
				v-model="dataToSend.personalDataCountry"
				:options="countries"
				label="text"
				:clearable="false"
				:reduce="(option) => option.value"
				@input="getCountry"
			/>
		</b-form-group>
		<VatChecker v-model="dataToSend.personalDataTva" @change="handleVatChange" />
		<google-auto-complete
			ref="step-three-google-search"
			:label="FormMSG(259, 'Internet search')"
			:put-search="searchAddress"
			:country="internetSearch"
			@placechanged="(item) => handleAutoComplete(item)"
		/>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(261, 'Company name')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.personalDataCompanyName.$model"
				:state="getStateValidationField('dataToSend.personalDataCompanyName')"
				:placeholder="FormMSG(1658, 'Enter company name')"
			/>
			<div
				v-if="$v.dataToSend.personalDataCompanyName.$error && getStateValidationField('dataToSend.personalDataCompanyName') !== null"
				class="invalid-feedback"
			>
				{{ FormMSG(1659, 'Please enter company name') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(262, 'Street')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.personalDataStreet.$model"
				:state="getStateValidationField('dataToSend.personalDataStreet')"
				:placeholder="FormMSG(1670, 'Enter street')"
			/>
			<div v-if="$v.dataToSend.personalDataStreet.$error && getStateValidationField('dataToSend.personalDataStreet') !== null" class="invalid-feedback">
				{{ FormMSG(1671, 'Please enter street') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(263, 'Number')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.personalDataNumber.$model"
				:state="getStateValidationField('dataToSend.personalDataNumber')"
				:placeholder="FormMSG(1672, 'Enter number')"
			/>
			<div v-if="$v.dataToSend.personalDataNumber.$error && getStateValidationField('dataToSend.personalDataNumber') !== null" class="invalid-feedback">
				{{ FormMSG(1673, 'Please enter number') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(264, 'Postal code')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.personalDataZip.$model"
				:state="getStateValidationField('dataToSend.personalDataZip')"
				:placeholder="FormMSG(1674, 'Enter postal code')"
			/>
			<div v-if="$v.dataToSend.personalDataZip.$error && getStateValidationField('dataToSend.personalDataZip') !== null" class="invalid-feedback">
				{{ FormMSG(1675, 'Please enter postal code') }}
			</div>
		</b-form-group>
		<b-form-group>
			<template #label>
				<custom-label-mandatory :label="FormMSG(265, 'City')" />
			</template>
			<b-form-input
				v-model="$v.dataToSend.personalDataCity.$model"
				:state="getStateValidationField('dataToSend.personalDataCity')"
				:placeholder="FormMSG(1676, 'Enter city')"
			/>
			<div v-if="$v.dataToSend.personalDataCity.$error && getStateValidationField('dataToSend.personalDataCity') !== null" class="invalid-feedback">
				{{ FormMSG(1677, 'Please enter city') }}
			</div>
		</b-form-group>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import CustomLabelMandatory from './CustomLabelMandatory.vue';
import { noTilde } from '~utils';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import GoogleAutoComplete from '@/components/GoogleAutoComplete';
import VatChecker from '@/components/VatChecker';

export default {
	name: 'StepThreeForm',

	mixins: [languageMessages, globalMixin, validationMixin],

	components: {
		CustomLabelMandatory,
		GoogleAutoComplete,
		VatChecker
	},

	props: {
		initData: { type: Object, default: () => {} }
	},

	data() {
		return {
			internetSearch: '',
			searchAddress: '',
			dataToSend: {
				personalDataCountry: 16965,
				personalDataTva: 0,
				personalDataCompanyName: null,
				personalDataStreet: null,
				personalDataNumber: null,
				personalDataZip: null,
				personalDataCity: null,

				contactName: null,
				contactEmail: null,
				contactPhone: ''
			}
		};
	},

	validations: {
		dataToSend: {
			personalDataCompanyName: { required },
			personalDataStreet: { required },
			personalDataNumber: { required },
			personalDataZip: { required },
			personalDataCity: { required },

			contactName: { required },
			contactEmail: { required, email }
		}
	},

	watch: {
		initData: {
			handler(newVal) {
				this.dataToSend = newVal;
			},
			immediate: true
		},
		dataToSend: {
			handler(newVal) {
				this.$emit('step-three-form:change', { data: newVal });
			},
			deep: true
		}
	},

	computed: {
		countries() {
			let countries = this.FormMenu(8);

			countries.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return countries;
		}
	},

	methods: {
		isInvalid() {
			this.$v.$touch();

			return this.$v.$invalid;
		},
		handleVatChange(vat) {
			this.dataToSend.personalDataCompanyName = vat.name;
			this.dataToSend.personalDataTva = vat.originVat;

			this.searchAddress = vat.address;
		},
		handleAutoComplete(addressData) {
			this.dataToSend.personalDataStreet = addressData.route || addressData.place.name;
			this.dataToSend.personalDataCity = addressData.locality;
			this.dataToSend.personalDataNumber = addressData.street_number || '';
			const findCountry = this.countries.find((option) => option.text === addressData.country);
			this.dataToSend.personalDataCountry = findCountry.value;
			// this.dataToSend.country = addressData.country;
			this.dataToSend.personalDataZip = addressData.postal_code;
		},
		getStateValidationField(field) {
			const customCond = `this.$v.${field}.$error === false`;
			if (eval(customCond)) {
				return null;
			}

			return false;
		},
		getCountry() {
			this.$refs['google-search-address'].clearSelected();
		}
	}
};
</script>
